<template>
	<a-modal title="提问题" v-model:visible="visible" :width="800" :destroyOnClose="true" :keyboard="false" :maskClosable="false"
	okText="提交"  cancelText="取消" :confirmLoading="submitLoading" @ok="submitBtn" class="community-form-hu1s">
		<a-form ref="communityFormRef" layout="vertical" :model="formData.info" :rules="formData.ruls">
			<a-form-item label="标题" name="title">
				<a-input placeholder="请输入问题标题" @change="formData.titleChange" :maxlength="50" v-model:value="formData.info.title" >
					<template #suffix>
						<span class="title-length-tip"> {{formData.titleNumber}} / 50字 </span>
					</template>
				</a-input>
			</a-form-item>
			<a-form-item label="问题描述" name="describe">
				<div>
					<a-textarea v-model:value="formData.info.describe" @change="formData.descChange" style="resize: none;" placeholder="请输入详细的问题描述" :maxlength="500" :rows="5" />
					<div class="title-length-tip-box">
						<span class="title-length-tip"> {{formData.descNumber}} / 500字 </span>
					</div>
				</div>
			</a-form-item>
			<a-form-item label="上传附件" name="enclosure_img">
				<div class="enclosure-images-upload">
					<div v-for="(imgItem,index) in attachUpload.lists" :key="imgItem.unique_id" class="enclosure-image-preview">
						 <a-image width="100%" height="100%" style="object-fit: contain;" :src="imgItem.preview_url?imgItem.preview_url:imgItem.url" />
						 <div v-if="!imgItem.status" @click="attachUpload.deleteImageFile(index)" class="enclosure-images-del"><span class="mbm-iconfont mbm-guanbi2fill"></span></div>
						 <div v-if="imgItem.status=='uploading'" class="enclosure-images-uploading">
							 <div class="enclosure-images-uploading-item">
								<div>{{imgItem.progress}}%</div>
								<a-progress strokeWidth="6" trailColor="white" :percent="imgItem.progress" status="active" :show-info="false"/>
							 </div>
						 </div>
					</div>
					<div class="enclosure-image-add" v-if="attachUpload.lists.length<3">
						<PlusOutlined style="font-size: 22px;" />
						<div class="community-upload-text">问题截图</div>
						<input ref="enclosureUploadImg" type="file" @change="attachUpload.selectImageFile($event)" accept="image/*" class="enclosure-upload-img-file">
					</div>
				</div>
			</a-form-item>
		</a-form>
	</a-modal>
</template>

<script>
	import { PlusOutlined, LoadingOutlined  } from '@ant-design/icons-vue';
	import baseFunc from '/src/config/base_func.js';
	import { v4 as uuidv4 } from 'uuid';
	import { message } from 'ant-design-vue';
	export default {
		name: 'CommunityQuiz',
		components: {
			PlusOutlined, LoadingOutlined
		},
		data() {
			return {
				userinfo:null,/*用户登录情况*/
				visible:false,//打开弹窗
				submitLoading:false,//提交问题
				attachUpload:{
					lists:[],
					deleteImageFile:(index)=>{
						this.attachUpload.lists.splice(index,1);
					},
					selectImageFile:(e)=>{
						let file = e.target.files[0];
						let checkExt = baseFunc.checkFileExt(baseFunc.getType(file.name,true),"img");
						if(checkExt){
							message.error(checkExt);
							return false;
						}
						if(file.size>10*1024*1024){
							message.error("最大支持10M，您选择的图片为"+baseFunc.converSize(file.size));
							return false;
						}
						let fileData={
							unique_id:uuidv4(),
							url:'',
							preview_url:'',
							attach_id:0,
							filename:file.name,
							filesize_byte:file.size,
							filesize:baseFunc.converSize(file.size),
							progress:0,
							status:null,//uploading：上传中 success:上传成功
							file:file,
						}
						this.attachUpload.lists.push(fileData);
						let index = this.attachUpload.lists.indexOf(fileData)
						try{
							this.attachUpload.lists[index].preview_url=URL.createObjectURL(file)
						}catch(err){
							let img = new FileReader();
							img.readAsDataURL(file);
							img.onload = ({target}) => {this.attachUpload.lists[index].preview_url=target.result};
						}
						this.$refs.enclosureUploadImg.value = null
					},
				},
				formData:{
					ruls:{
						title:[
							{
								required: true,
								message: '请输入标题',
								whitespace:true,//必选时，空格是否会被视为错误
							},
						],
					},
					info:{},
					titleNumber:0,
					descNumber:0,
					titleChange:(e)=>{
						this.formData.titleNumber=e.target.value.length
					},
					descChange:(e)=>{
						this.formData.descNumber=e.target.value.length
					},
				},
			}
		},
		props: {
		},
		watch: {
		},
		created() {},
		mounted() {},
		methods: {
			openCommunityQuiz(){
				/*判断用户是否登陆*/
				baseFunc.checkUserLogin(this,true,true).then(()=>{
					this.submitLoading=false;
					this.formData.titleNumber=0;
					this.formData.descNumber=0;
					this.formData.info={};
					this.attachUpload.lists=[];
					this.visible=true;
				}).catch((err)=>{
				})
			},
			/*循环上传图片*/
			async _uploadLoop(){
				let fileAttachs = this.attachUpload.lists,
					that = this,
					proms=[];
				for(let i=0;i<fileAttachs.length;i++){
					if(fileAttachs[i].status){
						continue;
					};
					that.attachUpload.lists[i].status="uploading";
					proms.push(
					    await new Promise((resolve,reject)=>{
							that.$apiRequest.uploadFileToAliOssOther(that,fileAttachs[i].file,{
								"index":i,
							},progress=>{
								/*进度条*/
								that.attachUpload.lists[progress.extra_data_defult.index].progress=progress.percent;
							},complete=>{
								// console.log("complete",complete)
							}).then(res => {
								that.attachUpload.lists[res.extra_data_defult.index].status="success";
								that.attachUpload.lists[res.extra_data_defult.index].url=res.url;
								that.attachUpload.lists[res.extra_data_defult.index].attach_id=res.id;
								that.attachUpload.lists[res.extra_data_defult.index].file=null;
								resolve()
							}).catch(err => {
								reject()
							});
						})
					)
				}
				return Promise.all(proms)
			},
			/*提交问题*/
			submitBtn(){
				/*点击确认*/
				this.$refs.communityFormRef.validate().then((result) => {
					this.submitLoading=true;
					this._uploadLoop().then(()=>{
						let enclosure_img = [];
						for(let i=0;i<this.attachUpload.lists.length;i++){
							if(this.attachUpload.lists[i]){
								enclosure_img.push(this.attachUpload.lists[i].url)
							}
						}
						this.$apiRequest.post("/user/questions", {
							title:result.title?result.title:'',
							describe:result.describe?result.describe:'',
							enclosure_img:enclosure_img
						}).then(res => {
							this.submitLoading=false;
							this.visible=false;
							message.success(res.data.message);
							this.$emit('communityQuizCallback',res)
						}).catch(err => {
							this.submitLoading=false;
						    if(err.data)this.$baseFunc.checkState(err.data)
						});
					})
					
				}).catch((err)=>{
					this.submitLoading=false;
				})
			}
		},
	}
</script>

<style>
	.community-form-hu1s .ant-form-item-label > label{font-weight: 600;}
	.community-form-hu1s .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{content: "";margin: 0;}
	.community-form-hu1s .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after{
		display: inline-block;
		color: #FF0000;
		font-size: 14px;
		line-height: 1;
		content: '*';
		font-weight: 400;
	}
	.community-form-hu1s .ant-modal-body{padding-bottom: 0;}
</style>
<style scoped>
	
	.enclosure-images-upload{
		width: 100%;
		font-size: 0;
		margin: 0;
		padding: 0;
		display: flex;
	}
	.enclosure-image-preview{
		text-align: center;
		width: 128px;
		height: 128px;
		background: #FAFAFA;
		border: 1px solid #DADDE1;
		box-sizing: border-box;
		border-radius: 2px;
		cursor: pointer;
		margin-right: 13px;
		position: relative;
	}
	.enclosure-images-del{
		position: absolute;
		top: -12px;
		right: -12px;
		width: 24px;
		height: 24px;
		z-index: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #929299;
		transition: color 0.2s;
	}
	.enclosure-images-del>span{
		font-size: 19px;
		line-height: 1;
		background-color: #ffffff;
		border-radius: 50%;
	}
	.enclosure-images-del:hover{
		color: #ff4d4f;
	}
	.enclosure-images-uploading{
		position: absolute;
		z-index: 2;
		background-color: rgba(0,0,0,0.5);
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		cursor: auto;
		padding: 0 20px;
		display: flex;
		align-items: center;
	}
	.enclosure-images-uploading .enclosure-images-uploading-item{
		color: #ffffff;
		font-size: 16px;
		text-align: center;
		width: 100%;
	}
	.enclosure-image-add{
		text-align: center;
		font-size: 12px;
		color: #949494;
		width: 128px;
		height: 128px;
		background: #FAFAFA;
		border: 1px dashed #E1E4E7;
		box-sizing: border-box;
		border-radius: 2px;
		cursor: pointer;
		padding-top: 36px;
		position: relative;
		transition: all 0.2s;
	}
	.enclosure-image-add:hover{border-color:#285AF9;}
	.enclosure-upload-img-file{
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0;
		width: 100%;
		height: 100%;
		cursor: pointer;
	}
	.title-length-tip-box{
		position: absolute;
		bottom: 7px;
		right: 11px;
	}
	.title-length-tip{
		font-size: 14px;
		color: #BFBFBF;
	}
	.community-upload-text{
		font-size: 12px;
		margin-top: 12px;
	}
</style>
