<template>
	<div class="">
		<div v-if="way!=3" class="login-warp1" :class="way==2?payStatus==1?'login-warp2 login-warp2-pay':'login-warp2':''">
			<div class="login-info">
				<img :src="userInfo && userInfo.headimgurl?userInfo.headimgurl:'/img/knowledgePayment/icon-weidenglu.png'">
				<span>{{userInfo?userInfo.nickname:"未登录"}}</span>
			</div>
			<div class="login-btn1" @click.stop="loginData.openModal">{{userInfo?'个人中心':'点击登录'}}</div>
		</div>
		<div class="login-footer-box" :class="loginData.visible?'show' : ''">
			<div class="login-footer-shade" @click="loginData.closeModal" ></div>
			<div class="login-footer-container" :class="wxLoginInfo.browserType=='wx'?'login-footer-wxx-container':''">
				<img class="login-img-close" src="/img/knowledgePayment/close.png"  @click="loginData.closeModal"/>
				<div class="login-con-box-s2">
					<div class="login-b-title">登录账号</div>
					<div class="login-input-phone-code">
						<div  class="login-input-btn-box">
							<input type="number" v-model.value="loginData.phone" class="ant-input-d" placeholder="请输入手机号" />
							<div class="login-phone-code-send" @click="loginData.sendCodesms" :class="loginData.sendStatus?'on':''"><span>{{loginData.sendStatus?loginData.countDown+'s':'获取验证码'}}</span></div>
						</div>
						<div  class="login-input-btn-box">
							<input type="number" v-model.value="loginData.smscode" class="ant-input-d" placeholder="请输入验证码" />
						</div>
					</div>
					<div class="submit-btn" :class="loginData.loginStatus?'on':''" @click="loginData.submit">{{loginData.loginStatus?"登录中…":"登录"}}</div>
					
					<div class="orther-login-box" v-if="wxLoginInfo.browserType=='wx'"  @click="wxLoginInfo.login(true)">
						<img src="https://qkabwjhf.tipian03.cn/image/wx.png" />
						<div class="orther-txt">微信登录</div>
					</div>

					<div class="submit-text">继续操作代表您已阅读并同意<span style="color: #4273F1;">提片网 www.tipian.com</span></div>
					<div class="submit-text"><a href="https://view-h.mbm07.cn/k77wan/M666G_V5EuCSp">用户协议</a>和<a href="https://view-h.mbm07.cn/k77wan/M666G_sJyyryK">隐私政策</a></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { message } from 'ant-design-vue';
	import config from '/src/config/config.js';
	export default {
		components: {},
		watch: {},
		props: {
			userInfo: Object||String,
			way:Number,/*登陆样式*/
			payStatus:Number,/*0：未支付 1：已支付*/
		},
		data() {
			return {
				loginData:{
					visible:false,
					phone:"",
					smscode:"",
					sendStatus:false,/*是否发送验证码*/
					countDown:60,/*倒计时*/
					timeer:null,
					loginStatus:false,/*登陆状态*/
					clearCodeTimeer:()=>{/*清除倒计时*/
						this.loginData.sendStatus=false;
						this.loginData.countDown=60;
						if(this.loginData.timeer){
							clearInterval(this.loginData.timeer);
							window.clearInterval(this.loginData.timeer);
							this.loginData.timeer = null;
						}
					},
					checkPhone:(val)=>{
						let reg =/^1[3-9]\d{9}$/;
						if(!val){
							message.error('请输入手机号');
							return false;
						}else{
							if(!reg.test(val)){
								message.error('请输入正确的手机号');
								return false;
							}else{
								return true;
							}
						}
					},
					sendCodesms:()=>{/*发送短信验证码*/
						let _this = this;
						if(this.loginData.sendStatus)return false;
						if(this.loginData.checkPhone(this.loginData.phone)){
							this.loginData.clearCodeTimeer();
							this.loginData.sendStatus=true;
							this.$apiRequest.post('/auth/sms', {
								phone: this.loginData.phone,
								use: "login"
							}).then(res => {
								_this.loginData.timeer  = setInterval(function(){
															_this.loginData.countDown--;
															if(_this.loginData.countDown<=0)
															_this.loginData.clearCodeTimeer();
														},1000)
								message.success(res.data.message)
							}).catch(err => {
								this.loginData.sendStatus=false;
								message.error(err.data.message)
							})
						}
					},
					closeModal:()=>{
						this.loginData.clearCodeTimeer();
						this.loginData.visible=false;
						this.loginData.phone="";
						this.loginData.smscode="";
						this.loginData.loginStatus = false;
					},
					openModal:()=>{
						if(this.userInfo){
							localStorage.setItem("record_url",encodeURIComponent(window.location.href))
							if(config.devEnv=="dev"){
								window.open('/h5/user/home.html');
							}else{
								window.location.href = config.h5Urls.home;
							}
						}else{
							this.loginData.visible=true;
						}
					},
					submit:()=>{
						if(!this.loginData.loginStatus&&this.loginData.checkPhone(this.loginData.phone)){
							if (this.loginData.smscode == "") {
								message.error("验证码不能为空")
								return
							}else{
								this.loginData.loginStatus = true;
								this.$apiRequest.post('/auth/login', {
									login_type: "phonesms",
									phone: this.loginData.phone,
									smscode: this.loginData.smscode
								}).then(res => {
									this.loginData.loginStatus = false;
									this.backLoginSuccessFunc(res.data.user);
									message.success(res.data.message);
									this.loginData.closeModal();
								}).catch(err => {
									this.loginData.loginStatus = false;
									if(err.data)message.error(err.data.message)
								})
							}
						}
					}
				},
				wxLoginInfo:{
					browserType:"",/*wx：微信浏览器 h5：非微信浏览器 pc：pc系统 */
					browserTypeInit:()=>{/*初始化获取浏览器类型*/
						if(show_wx_login == "1"){
							let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
							if(flag){
								if (navigator.userAgent.toLowerCase().match(/MicroMessenger/i) == "micromessenger") {
									this.wxLoginInfo.browserType = 'wx';
								}else{
									this.wxLoginInfo.browserType = 'h5';
								}
							}else{
								this.wxLoginInfo.browserType = 'pc';
							}
						}
					},
					login:(status)=>{
						if(!this.loginData.loginStatus){
							if(unionid){
								this.loginData.loginStatus = true;
								this.$apiRequest.post('/auth/login', {
									login_type: "chat",
									unionid: unionid,
									openid: gzh_openid
								}).then(res => {
									this.loginData.loginStatus = false;
									this.backLoginSuccessFunc(res.data.user);
									message.success(res.data.message);
									this.loginData.closeModal();
								}).catch(err => {
									this.loginData.loginStatus = false;
									if(err.data)message.error(err.data.message)
								})
							}else if(status){
								var nowuri = window.location.href;
								if(nowuri.indexOf("?")!=-1){
									nowuri = nowuri+"&c=1"
								}else{
									nowuri = nowuri+"?c=1"
								}
								window.location.href = nowuri
							}
						}
					}
				}
			}
		},
		created() {
			// 判断用户是否已经登陆 getItem removeItem setItem
			this.wxLoginInfo.browserTypeInit()
			let userLogRecord =localStorage.getItem("user_log_record");
			if(userLogRecord && !this.userInfo){
				this.getUserMess(0,userLogRecord);
			}else if(!this.userInfo){
				this.wxLoginInfo.login(false);
			}
		},
		methods: {
			getUserMess(uid,uni_key){
				let mo_gzh_openid='';
				if(typeof gzh_openid!='undefined')mo_gzh_openid=gzh_openid
				this.$apiRequest.post('/auth/getUserMess', {
					uid:uid,
					uni_key: uni_key,
					gzh_openid:mo_gzh_openid,
				}).then(res => {
					this.backLoginSuccessFunc(res.data.user);
				}).catch(err => {
					this.wxLoginInfo.login(false);
					localStorage.removeItem("user_log_record");
				})
			},
			backLoginSuccessFunc(userData){
				if(userData){
					if(userData.uni_key)localStorage.setItem("user_log_record",userData.uni_key)
					this.$emit('backLoginSuccess', {user:userData})
				}
			}
		},
		computed: {},
	}
</script>

<style scoped lang="less">
	.login-warp1{
		width: calc(100% - 0.64rem);
		padding: 0.3rem 0.32rem;
		box-sizing: border-box;
		margin: 0 auto;
		left: 0;
		right: 0;
		position: fixed;
		bottom: 0.78rem;
		height: 1.3rem;
		border-radius: 0.65rem;
		box-shadow: 0 0 0.22rem 0.02rem rgba(0,0,0,0.05);
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: #ffffff;
		.login-info{
			height: 0.7rem;
			display: flex;
			align-items: center;
			justify-content: center;
			img{
				width: 0.7rem;
				height: 0.7rem;
				object-fit: cover;
				border-radius: 50%;
				margin-right: 0.22rem;
			}
			span{
				font-size: 0.28rem;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #333333;
				line-height: 1;
			}
		}
		.login-btn1{
			height: 0.64rem;
			display: flex;
			cursor: pointer;
			align-items: center;
			justify-content: center;
			padding: 0 0.29rem;
			font-size: 0.28rem;
			background-color: #4273F1;
			color: #ffffff;
			border-radius: 0.32rem;
		}
	}
	.login-warp2{
		bottom: 1.1rem;
		left: 0;
		background-color: #F5FAFF;
		border-radius: 0;
		width: 100%;
		.login-btn1{
			height: 0.7rem;
			padding: 0 0.36rem;
			border-radius: 0.36rem;
			background-color: #E4F2FF;
			color: #4273F1;
		}
	}
	.login-warp2.login-warp2-pay{
		bottom: 0;
	}
	.login-footer-box.show .login-footer-container {-webkit-transform: translate3d(0,0%,0);transform: translate3d(0,0%,0);pointer-events: auto;}
	.login-footer-box.show .login-footer-shade {opacity: 1;pointer-events: auto;}
	.login-footer-shade{overflow: hidden;background-color:rgba(0,0,0,0.2);position:fixed;z-index:8;width:100%;height:100vh;left:0;right:0;bottom:0;top:0;opacity:0;pointer-events:none;transition:opacity .3s cubic-bezier(0,0,.25,1);cursor: pointer;}
	.login-footer-container{
		background-color:#fff;position:fixed;z-index:10;left:0;right:0;bottom:0;height:5.82rem;transform:translate3d(0,100%,0);transition:transform .3s cubic-bezier(0,0,.25,1);pointer-events:none;will-change:transform;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;overflow:hidden;border-top-left-radius:0.24rem;border-top-right-radius:0.24rem;
		&.login-footer-wxx-container{
			height: 7.6rem;
		}
		.login-img-close{
			position: absolute;
			top: 0.1rem;
			right: 0.15rem;
			width: 0.77rem;
			padding: 0.2rem;
			cursor: pointer;
		}
		.login-con-box-s2{
			position: relative;
			top: 0.6rem;
			width: 100%;
			padding: 0 0.64rem;
			box-sizing: border-box;
			text-align: center;
			.login-b-title{
				font-size: 0.32rem;
				font-weight: 500;
				color: #333333;
			}
			.login-input-phone-code{
				width: 100%;
				position: relative;
				margin: 0.36rem 0;
				padding: 0;
				.login-input-btn-box{
					width: 100%;
					height: 0.88rem;
					background-color: #F6F6F6;
					border-radius: 0.16rem;
					display: flex;
					align-items: center;
					justify-content: center;
					.ant-input-d{
						flex: 1;
						height: 100%;
						display: flex;
						align-items: center;
						outline: none !important;
						box-sizing: border-box;
						margin: 0;
						font-variant: tabular-nums;
						list-style: none;
						font-feature-settings: 'tnum';
						position: relative;
						display: inline-block;
						width: 100%;
						padding: 0 0.26rem;
						color: rgba(0, 0, 0, 0.85);
						font-size: 0.28rem;
						line-height: 1.5715;
						background-color: rgba(0,0,0,0);
						background-image: none;
						border: 0;
						transition: all 0.3s;
					}
					.login-phone-code-send{
						width: 1.92rem;
						height: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
						position: relative;
						cursor: pointer;
						font-size: 0.28rem;
						color: #333333;
						::after{
							position: absolute;
							content: "";
							left: 0;
							top: calc(50% - 0.18rem);
							width: 0.01rem;
							height: 0.36rem;
							background-color: #cccccc;
						}
					}
					.login-phone-code-send.on{
						color: #999999;
						pointer-events:none;
					}
				}
				.login-input-btn-box:first-child{
					margin-bottom: 0.16rem;
				}
			}
			.submit-btn{
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 0.92rem;
				background-color:#4273F1;
				border-radius: 0.46rem;
				font-size: 0.36rem;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #FFFFFF;
				cursor: pointer;
				margin-bottom: 0.2rem;
			}
			.submit-btn.on{
				opacity: 0.8;
				pointer-events:none;
			}
			.orther-login-box{
				margin-top: 0.4rem;
				margin-bottom: 0.2rem;
				cursor: pointer;
				img {
					width: 0.9rem;
					height: 0.9rem;
					margin: 0 auto;
				}
				.orther-txt{
					font-size: 0.28rem;
					color: #999999;
				}
			}
			.submit-text{
				width: 100%;
				text-align: center;
				color: #999999;
				font-size: 0.22rem;
				span,a{
					color:#7D7D7D;
					margin:0 .08rem;
					cursor: pointer;
				}
				a:last-child,span:last-child{margin-right: 0;}
			}
		}
		
	}
</style>
