<template>
	<div class="box">
		<a-modal class="modal" v-model:visible="modalVisible" title="设置链接" width="1000px" @cancel="cancel" @ok="submit" :destroyOnClose="true"
			okText="确定" cancelText="取消">
			<div class="contetn">
				<div class="left">
					<div class="left_list">
						<div :class="['left_list_text',leftMenu.leftMenuIndex == index ? 'left_list_text_color':'']"
							v-for="(item,index) in leftMenu.leftMenuList" :key="index"
							@click="leftMenu.listIndexTabs(index)">
							<i :class="['mbm-iconfont', 'icon', item.icon]"></i>
							<span style="margin-left: 12px;">{{item.title}}</span>
						</div>
					</div>
				</div>
				<div class="right">
					<div class="liveCode" v-if="leftMenu.leftMenuIndex == 0">
						<div class="right_l">
							<div :class="['right_l_tree',leftMenu.leftCodeIndex==index?'right_l_tree_color':'']"
								v-for="(item,index) in leftMenu.leftCodeList" :key="index"
								@click="leftMenu.listCodeTabs(index)">
								<span>{{item.title}}</span>
								<span>{{item.total}}</span>
							</div>
						</div>
						<div class="right_r">
							<div class="right_r_title">
								<div class="right_r_title_text">全部活码</div>
								<div class="right_r_title_input">
									<div class="right_r_title_input_box">
										<input type="text" v-model="keywords" @keydown.enter="searchCode"
											:allowClear="true" placeholder="请输入内容，按回车键搜索">
										<i class="mbm-iconfont icon mbm-suosou"
											style="width:24px;line-height:24px;margin-left:8px;cursor: pointer;"
											@click="searchCode"></i>
									</div>
								</div>
							</div>
							<a-table :columns="columns" :data-source="tableData" :rowSelection="rowSelection"
								row-key="id" :scroll="{y:426}" :pagination="pageObj" @change="handlePageChange" :loading="tableLoad">
								<template #codeLink="{ record }">
									<div @click="preview.openModal(record)">
										<span style="color: #285af9;cursor: pointer;">预览</span>
									</div>
								</template>
							</a-table>
						</div>
					</div>
					<div class="website" v-else-if="leftMenu.leftMenuIndex == 1">
						<div class="website_title">网址</div>
						<a-input v-model:value="jumpLinkObj.original_url" placeholder="请输入网址" />
						<div class="website_tips"><i class="mbm-iconfont icon mbm-gantanhao11-copy" style="color: #ffa700;margin-right: 3px;"></i>如果打开二维码的方式是小程序，链接无法直接跳转。如需直接打开网址，需要切换为H5打开。
						</div>
					</div>
					<div class="phone" v-else-if="leftMenu.leftMenuIndex == 2">
						<div class="phone_title">电话</div>
						<a-input v-model:value="jumpLinkObj.original_url" placeholder="请输入电话号码" />
					</div>
					<div class="other" v-else>
						<div class="other_title">其他小程序</div>
						<div class="other_tips">
							<span>小程序外链功能，可以跳转至任意小程序页面。</span>
							<span style="color:#285AF9;cursor: pointer;" @click="insert">插入实例数据</span>
						</div>
						<div class="other_input_box">
							<div>
								<span>AppId:</span>
								<a-input v-model:value="jumpLinkObj.appid"
									placeholder="打开需要跳转的小程序→点击右上角「…」→查看小程序详情→更多资料→找到wx开头的18位字符" />
							</div>
							<div>
								<span>账号原石ID:</span>
								<a-input v-model:value="jumpLinkObj.original_id"
									placeholder="打开需要跳转的小程序→点击右上角「…」→查看小程序详情→更多资料→找到gh开头的15位字符" />
							</div>
							<div>
								<span>小程序路径:</span>
								<a-input v-model:value="jumpLinkObj.chat_path"
									placeholder="如你不是小程序的开发者，可通过微信公众平台，获取任意小程序页面的访问路径" />
							</div>
							<div>
								<span>链接文字:</span>
								<a-input v-model:value="jumpLinkObj.chat_name" placeholder="请输入链接文字" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</a-modal>
		<!-- 二维码详细设置 -->
		<a-modal wrapClassName="qrcode-set-warp-xj18sh2" :destroyInClose="true" :footer="null" :centered="true" width="1000px" :visible="preview.previewBool" title="预览" @cancel="preview.cancel">
			<div class="qrcode-set-box-h18s">
				<settings :styleConfig="preview.styleConfig" :showConfig="preview.showConfig"  @getsetData="preview.callSetData"  :idKey="preview.info?preview.info.id_key:''"></settings>
			</div>
		</a-modal>
	</div>

</template>

<script>
	import {
		message
	} from 'ant-design-vue';
	import settings from "@/components/settings.vue";
	import baseFunc from "@/config/base_func.js";
	export default {
		components: {
			settings
		},
		props: {
			jumpLink: Object,
			visible: Boolean
		},
		data() {
			return {
				modalVisible: false, //是否显示
				jumpLinkObj: this.jumpLink, //链接
				keywords: "", //搜索标题
				tableLoad:false,/*数据加载中*/
				leftMenu: { //左侧菜单
					leftMenuIndex: 0, //左侧菜单栏下标
					leftCodeIndex: 0, //左侧活码下标
					leftMenuList: [ //左侧菜单列表
						{
							title: "活码",
							icon: "mbm-erweima1"
						}, {
							title: "网址",
							icon: "mbm-wangzhi"
						}, {
							title: "电话",
							icon: "mbm-dianhua"
						}, {
							title: "其他小程序",
							icon: "mbm-xiaochengxu2"
						},
					],
					leftCodeList: [ //左侧活码列表
						{
							title: "全部活码",
							type: -4,
							total: 0
						}, {
							title: "全部链接",
							type: -5,
							total: 0
						}
					],
					listIndexTabs: index => { //列表下标切换
						this.leftMenu.leftMenuIndex = index
						this.jumpLinkObj.type_name = this.leftMenu.leftMenuList[index].title
						switch (index) {
							case 0:
								this.jumpLinkObj.type = "qrcode"
								break;
							case 1:
								this.jumpLinkObj.type = "web"
								break;
							case 2:
								this.jumpLinkObj.type = "tel"
								break;
							case 3:
								this.jumpLinkObj.type = "chat"
								break;
						}
					},
					listCodeTabs: index => { //活码下标切换
						this.leftMenu.leftCodeIndex = index
						this.pageObj.current=1;
						this.getInitList(index)
					}
				},
				pageObj: { //分页配置
					current: 1,
					pageSize: 20,
					total: 1
				},
				preview: { //预览
					previewBool: false, //多视频预览
					info: null, //数据
					styleConfig: {
						iframe: {
							// width:'200px',//默认320px
							// height:'400px',//默认900px
							//...
						},
						settings: {
							// width:'300px',//默认500px
							//...
						}
					},
					//显示内容设置，可不填
					showConfig: {
						iframe: true, //显示左侧预览iframe
						settings: true, //显示右侧设置整体
						settings_qrcode: true, //设置头部二维码图片+标题
						settings_list: true, //二维码设置部分收起/展开
						settings_list_expire: true, //设置有效期
						settings_list_auth: true, //设置查看权限
						settings_list_open: true, //设置打开方式（链接类型此项不论设置什么值最终都为false）
						settings_list_bot: true, //底部设置
						settings_list_video: true //视频下载设置
					},
					openModal: item => { //预览
						this.preview.info = item
						this.preview.previewBool = true;
					},
					cancel:()=>{/*关闭弹窗*/
						this.preview.previewBool = false;
					}
				},
				rowSelection: { //表格选项配置
					type: "radio",
					onChange: (selectedRowKeys, selectedRows) => {
						this.jumpLinkObj.id = selectedRows[0].id
						this.jumpLinkObj.id_key = selectedRows[0].id_key
						this.jumpLinkObj.qrcode_type = selectedRows[0].charge_type
						this.jumpLinkObj.title = selectedRows[0].filename
					}
				},
				columns: [ //表格配置
					{
						title: '二维码名称',
						dataIndex: 'filename',
						key: 'id',
						width: '277px'
					},
					{
						title: '最后更新时间',
						dataIndex: 'updated_at',
						key: 'id',
					},
					{
						title: '操作',
						key: 'id',
						slots: {
							customRender: "codeLink"
						},
						width: '100px'
					},
				],
				tableData: [ //表格数据

				],
			};
		},
		mounted() {},
		created() {

		},
		beforeDestroy() {},
		watch: {
			visible: {
				deep:true,
				handler(newVal, oldVal) {
					this.modalVisible = newVal
					if (newVal) {
						this.jumpLinkObj=this.jumpLink;
						if(this.jumpLinkObj && this.jumpLinkObj.type){
							switch (this.jumpLinkObj.type) {
								case "qrcode":
									this.leftMenu.leftMenuIndex = 0
									break;
								case "web":
									this.leftMenu.leftMenuIndex = 1
									break;
								case "tel":
									this.leftMenu.leftMenuIndex = 2
									break;
								case "chat":
									this.leftMenu.leftMenuIndex = 3
									break;
							}
						}
						
						this.leftMenu.leftMenuIndex
						
						this.pageObj.current=1;
						this.getInitList(0)
						this.getInitList(1,1)
					}
				}
			}
		},
		methods: {
			getInitList(index,status) { //获取活码列表
				let type = -4
				if (index) {
					type = -5
				} else {
					type = -4
				}
				this.tableLoad=true;
				this.$apiRequest.post("/user/CatalogData", {
					id: 0,
					type,
					size: 20,
					page: this.pageObj.current,
					keywords: this.keywords
				}).then(res => {
					this.leftMenu.leftCodeList[index].total = res.data.data.total
					if(status!=1){
						this.tableData = res.data.data.data
						this.pageObj.total = res.data.data.total
					}
					this.tableLoad=false;
				}).catch(err => {
					if(err.data && err.data.code==11000){
						baseFunc.checkState(err.data);
					}
					this.tableLoad=false;
				});
			},
			submit() { //提交
				let empty = {
					"type": '',/*web:网址 tel:电话 chat: 其他小程序 qrcode: 活码*/
					"type_name": "",
					/*类型说明*/
					"url": "",
					/*转换后的数据*/
					"original_url": "",
					/*用户输入框填写的数据*/
					"appid": "",
					/*跳转其他小程序 需要的appid*/
					"original_id": "",
					/*跳转其他小程序 需要的原始id*/
					"chat_path": "",
					/*跳转其他小程序 路径*/
					"chat_name": "",
					/*跳专其他小程序 名称 (linkType=="moudle"无效)*/
					"id_key": "",
					/*活码id key*/
					"id": 0,
					/*活码id*/
					"qrcode_type": "",
					/*活码类型*/
					"title": "" /*活码标题*/
				}
				switch (this.leftMenu.leftMenuIndex) {
					case 0:
						if (this.jumpLinkObj.id == 0) {
							message.warning("请选择活码")
							return
						}
						this.jumpLinkObj = {
							...empty,
							type: "qrcode",
							type_name: "活码",
							id: this.jumpLinkObj.id,
							id_key: this.jumpLinkObj.id_key,
							qrcode_type: this.jumpLinkObj.qrcode_type,
							title: this.jumpLinkObj.title,
						}
						break;
					case 1:
						if (this.jumpLinkObj.original_url == "") {
							message.warning("请输入网址")
							return
						}
						// 自动补全网址
						let url = this.jumpLinkObj.original_url;
						if(url.indexOf("http://") < 0&&url.indexOf("https://") < 0&&url.indexOf("ftp://") < 0&&url.indexOf("rtsp://") < 0&&url.indexOf("mms://") < 0)url='http://'+url;				
						this.jumpLinkObj = {
							...empty,
							type: "web",
							type_name: "网址",
							url: url,
							original_url: this.jumpLinkObj.original_url,
						}
						break;
					case 2:
						if (this.jumpLinkObj.original_url == "") {
							message.warning("请输入手机号")
							return
						}
						this.jumpLinkObj = {
							...empty,
							type: "tel",
							type_name: "电话",
							url: `tel:${this.jumpLinkObj.original_url}`,
							original_url: this.jumpLinkObj.original_url,
						}
						break;
					case 3:
						if (this.jumpLinkObj.appid == "") {
							message.warning("请输入appid")
							return
						} else if (this.jumpLinkObj.chat_path == "") {
							message.warning("请输入路径")
							return
						}
						this.jumpLinkObj = {
							...empty,
							type: "chat",
							type_name: "其他小程序",
							appid: this.jumpLinkObj.appid,
							original_id: this.jumpLinkObj.original_id,
							chat_path: this.jumpLinkObj.chat_path,
							chat_name: this.jumpLinkObj.chat_name || "其他小程序",
						}
						break;
				}
				this.$emit('submit', this.jumpLinkObj)
				this.cancel()
			},
			cancel() { //关闭页面
				this.$emit('cancel', false)
			},
			insert() { //其他小程序插入示例数据
				let obj = {
					...this.jumpLink,
					appid: "wx3a5ba74c196aaca1",
					/*跳转其他小程序 需要的appid*/
					original_id: "gh_d16d0edf6410",
					/*跳转其他小程序 需要的原始id*/
					chat_path: "/pages/index/index",
					/*跳转其他小程序 路径*/
					chat_name: "其它小程序",
					/*跳专其他小程序 名称 (linkType=="moudle"无效)*/
				}
				this.jumpLinkObj = obj
			},
			handlePageChange(page) { //切换分页
				this.pageObj.current = page.current
				this.getInitList(this.leftMenu.leftCodeIndex)
			},
			searchCode() { //搜索活吗
				this.getInitList(this.leftMenu.leftCodeIndex)
			}
		},
	};
</script>

<style scoped lang="less">
	.icon {
		width: 16px;
		line-height: 16px;
		vertical-align: middle;
	}

	/deep/.btns {
		line-height: 30px;
		padding: 0 15px;
		border-radius: 4px;
		border: 1px solid #D9D9D9;
		display: flex;
		justify-content: center;
		align-items: center;
	}


	.contetn {
		width: 1000px;
		height: 600px;
		background: #fff;
		display: flex;

		.left {
			width: 160px;
			padding: 8px 16px;
			box-sizing: border-box;
			border-right: 1px solid #e8e8e8;
			height: 100%;

			.left_list {
				margin-top: 16px;
				width: 100%;

				.left_list_text {
					width: 100%;
					cursor: pointer;
					transition: color 0.2s;
					display: flex;
					align-items: center;
					justify-content: flex-start;
					height: 44px;

					&:hover {
						color: #285AF9;
					}
				}

				.left_list_text_color {
					color: #285AF9;
				}
			}
		}

		.right {
			width: 840px;

			.liveCode {
				display: flex;
				height: 100%;

				.right_l {
					width: 199px;
					height: 100%;
					border-right: 1px solid #e8e8e8;
					padding-top: 16px;

					.right_l_tree {
						background-color: #FFF;
						color: #333;
						width: calc(100% - 16px);
						margin: 0 8px;
						cursor: pointer;
						transition: all 0.2s;
						height: 36px;
						display: flex;
						justify-content: space-between;
						padding: 0 8px;
						box-sizing: border-box;
						align-items: center;
					}

					.right_l_tree_color {
						color: #285AF9;
						background-color: #F7F9F7;
					}
				}

				.right_r {
					width: 639px;
					box-sizing: border-box;
					padding: 16px;

					.right_r_title {
						width: 100%;
						height: 32px;
						display: flex;
						align-items: center;
						font-size: 16px;
						color: #333;
						justify-content: space-between;
						margin-bottom: 10px;

						.right_r_title_input {
							display: flex;
							align-items: center;
							height: 100%;
							justify-content: flex-end;

							.right_r_title_input_box {
								width: auto;
								display: flex;
								height: 100%;
								align-items: center;

								input {
									border: 0;
									border-bottom: 1px solid #e7e7eb;
									width: 200px;
									border-radius: 0;
									font-size: 14px;
									color: #333;

									&:focus {
										border: none;
										outline: none;
										border-bottom: 1px solid #285AF9;
									}

									&:hover {
										border-bottom: 1px solid #285AF9;
									}

									&::-webkit-input-placeholder {
										color: #ccc;
										font-size: 14px;
									}
								}
							}
						}
					}

					/deep/.ant-table-body {
						&::-webkit-scrollbar {
							width: 8px !important;
							background: transparent !important;
							border-radius: 3px !important;
						}

						&::-webkit-scrollbar-thumb {
							width: 6px !important;
							background-color: #ccc !important;
							border-radius: 3px !important;
						}

						&::-webkit-scrollbar-track {
							// 滚动条里面轨道
							background: rgba(0, 0, 0, 0.05) !important;
							background: none !important;
						}

						// 滚动条的交叉处正方形
						&::-webkit-scrollbar-corner {
							background-color: transparent !important;
						}
					}
				}
			}

			.website {
				padding: 0 24px;
				width: 100%;
				position: relative;
				box-sizing: border-box;

				.website_title {
					margin-top: 24px;
					margin-bottom: 16px;
					color: #333;
					font-size: 16px;
				}

				.website_tips {
					color: #333;
					font-size: 14px;
					margin-top: 16px;
					display: flex;
					align-items: center;
				}
			}

			.phone {
				padding: 0 24px;
				width: 100%;
				position: relative;
				box-sizing: border-box;

				.phone_title {
					margin-top: 24px;
					margin-bottom: 16px;
					color: #333;
					font-size: 16px;
				}
			}

			.other {
				padding: 0 24px;
				width: 100%;
				position: relative;
				box-sizing: border-box;

				.other_title {
					margin-top: 24px;
					margin-bottom: 16px;
					color: #333;
					font-size: 16px;
				}

				.other_tips {
					color: #333;
					font-size: 14px;
					margin-top: 16px;
					margin-bottom: 16px;
					display: flex;
					justify-content: space-between;
				}

				.other_input_box {
					div {
						display: flex;
						align-items: center;
						margin-bottom: 8px;
					}

					span {
						width: 112px;
						line-height: 32px;
						color: #000000A6;
					}
				}
			}
		}
	}
</style>

<style>
	.modal .ant-modal-body {
		padding: 0 !important;
	}
</style>
