<template>
	<div class="noCheckQrcodeBox">
		
		<div v-if="info.type == 10107" class="mbm_password_box">
			<div class="noCheckQrcodeBox_content_title mbm_password_title">{{info.data.title}}</div>
			<a-input type="password" class="mbm_password_input" v-model:value="password" placeholder="请输入核销码" />
			<div class="mbm_login_box_bottom">
				<div class="mbm_login_btn_box">
					<a-button type="primary" class="mbm_login_btn" @click="submit('verifice')" :loading="loginLoading">确定</a-button>
				</div>
			</div>
		</div>
		
		<div v-if="info.type == 1" class="noCheckQrcodeBox_content">
			<img :src="info.data.img" />
			<div class="noCheckQrcodeBox_content_title">{{info.data.title}}</div>
			<div class="noCheckQrcodeBox_content_subtitle">{{info.data.desc_one+info.data.desc_two}}</div>
		</div>

		<div v-if="info.type == 2" class="mbm_password_box">
			<div class="noCheckQrcodeBox_content_title mbm_password_title">{{info.data.title}}</div>
			<div class="noCheckQrcodeBox_content_subtitle mbm_password_subtitle">密码提示:{{info.data.desc_one}}</div>
			<a-input type="password" class="mbm_password_input" v-model:value="password" placeholder="请输入访问密码" />
			<div class="mbm_login_box_bottom">
				<div class="mbm_radio_box" @click="radioChange">
					<a-radio class="mbm_radio" type="radio" value="自动登录" v-model:checked="isAutomaticLogon" />
					<span class="mbm_radio_text">下次自动进入</span>
				</div>
				<div class="mbm_login_btn_box">
					<a-button type="primary" class="mbm_login_btn" @click="submit('pwd')" :loading="loginLoading">确定
					</a-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {message} from 'ant-design-vue';
	export default {
		name: 'cantcheckQrcode',
		props: {
			info: Object,
			id_key: String,
		},
		data() {
			return {
				isAutomaticLogon: false,
				loginLoading: false,
				password: '',
			}
		},
		watch: {
			info:{
				handler(newVal, oldVal) {
					// console.log(newVal,oldVal)
				},
				deep:true// 深度监听父组件传过来对象变化
			}
		},
		created() {
			let storageInfo =localStorage.getItem(this.id_key);
			if (storageInfo != null) {
				this.isAutomaticLogon = JSON.parse(storageInfo).isAutomaticLogon;
				this.password = JSON.parse(storageInfo).pwd;
			}
		},
		methods: {
			radioChange() {
				if (this.isAutomaticLogon) {
					this.isAutomaticLogon = false;
					localStorage.setItem(id_key, {
						pwd: '',
						isAutomaticLogon: false
					});
				} else {
					this.isAutomaticLogon = true;
				}
			},
			passwordChange(e) {

			},
			submit(way) {
				let pwdReg = /^[a-zA-Z0-9]{1,20}$/;
				let pwd = this.password;
				if (pwdReg.test(pwd)) {
					this.$emit('onCheckSubmit', {
						way:way,
						pwd: pwd,
						verifice_code:pwd,
						isAutomaticLogon: this.isAutomaticLogon,
					})
				} else {
					if(way=='verifice'){
						message.error("请输入核销码");
					}else{
						message.error("请输入密码");
					}
					
				}
			},
		}
	}
</script>

<style>
	.noCheckQrcodeBox {
		width: 100%;
		height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 0.3rem;
		box-sizing: border-box;
		background: #EDEDED;
	}

	.noCheckQrcodeBox_content {
		text-align: center;
		max-width: 6rem;
	}

	.noCheckQrcodeBox_content>img {
		width: 2.8rem;
		height: 2.8rem;
		margin-bottom: 0.6rem;
	}

	.noCheckQrcodeBox_content_title {
		font-size: 0.28rem;
		line-height: 1.5;
		color: #333333;
	}

	.noCheckQrcodeBox_content_subtitle {
		margin-top: 0.24rem;
		font-size: 0.24rem;
		line-height: 1.5;
		color: #999999;
	}

	.mbm_password_box {
		width: 100%;
		text-align: center;
	}

	.mbm_password_title {
		font-size: 0.4rem;
		font-weight: bold;
		margin-bottom: 1rem;
	}

	.mbm_password_subtitle {
		font-size: 0.28rem;
		margin-bottom: 0.28rem;
	}

	.mbm_password_input {
		width: 100%;
		height: 0.96rem;
		border: 0.02rem solid #E9E9E9;
		background-color: #ffffff;
		font-size: 0.3rem;
		text-align: center;
	}

	.mbm_login_box_bottom {
		margin-top: 3.2rem;
		text-align: center;
	}

	.mbm_radio_box {
		display: flex;
		align-items: center;
		justify-content: center;
		line-height: 1;
	}

	.mbm_radio {
		/* height: 0.32rem;
		width: 0.32rem;
		border-color: #999999; */
	}

	.mbm_radio_text {
		font-size: 0.24rem;
		color: #585858;
		line-height: 1;
	}

	.mbm_login_btn_box {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.mbm_login_btn {
		width: 3.6rem !important;
		height: 0.84rem !important;
		border-radius: 0.08rem !important;
		color: #ffffff;
		font-size: 0.3rem !important;
		margin-top: 0.5rem !important;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.mbm_login_cancel {
		margin-top: 0.68rem;
		font-size: 0.32rem;
		color: #1c4da8;
	}
</style>
