<template>
	<a-modal :visible="showtypeVisible" :footer="null" :destroyOnClose="destroyOnClose" @ok="handleOk" @cancel="handleCancle" :title="mediatype==1?'视频款式预览':'音频款式预览'" width="1000px">
	    <div class="container">
			<div class="lists">
				<img :src="mediatype==1?'/img/design/video/video_one.jpg':'/img/design/audio/audio_one.jpg'" >
				<p>单个{{mediatype==1?'视频':'音频'}}</p>
				<div class="hoverDiv">
					<div>
						<img :src="mediatype==1?'/img/design/video/video_one_p.png':'/img/design/audio/audio_one_p.png'" classs="hoverDiv2">
						<p>扫码看效果</p>
					</div>
				</div>
			</div>
			<div class="lists">
				<img :src="mediatype==1?'/img/design/video/video_two.jpg?t=1':'/img/design/audio/audio_two.jpg?t=1'" >
				<p>{{mediatype==1?'视频':'音频'}}集合</p>
				<div class="hoverDiv">
					<div>
						<img :src="mediatype==1?'/img/design/video/video_two_p.png':'/img/design/audio/audio_two_p.png'" classs="hoverDiv2">
						<p>扫码看效果</p>
					</div>
				</div>
			</div>
			<div class="lists">
				<img :src="mediatype==1?'/img/design/video/video_brush.jpg?t=1':'/img/design/audio/audio_brush.jpg?t=1'" >
				<p>{{mediatype==1?'视频':'音频'}}刷一刷</p>
				<div class="hoverDiv">
					<div>
						<img :src="mediatype==1?'/img/design/video/video_brush_p.png':'/img/design/audio/audio_brush_p.png'" classs="hoverDiv2">
						<p>扫码看效果</p>
					</div>
				</div>
			</div>
		</div>
		<div class="qrCode">
			
		</div>
	</a-modal>
</template>

<script>
	export default {
		data(){
			return{
				destroyOnClose:true
			}
		},
		props:{
			showtypeVisible:Boolean,
			mediatype:Number
		},
		methods:{
			handleCancle(){
				this.$emit("closeVideotypesetModal")
			},
			handleOk(){
				this.$emit("closeVideotypesetModal")
			}
		},
		computed:{
			
		}
	}
</script>

<style scoped>
	.hoverDiv img{
		width: 200px;
		height: 200px;
	}
	.hoverDiv2{
		width: 100%;
		font-size: 16px;
		text-align: center;
		color: #ffffff;
	}
	.lists:hover .hoverDiv{
		top: 0;
	}
	
	.hoverDiv{
		width: 100%;
		height: 575px;
		background-color: rgba(0,0,0,.1);
		position: absolute;
		left: 0;
		top: -575px;
		transition: all .5s;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		border-radius: 20px;
	}
	.lists p{
		font-size: 16px;
		line-height: 16px;
		margin-top: 20px;
		text-align: center;
		margin-bottom: 0px;
		
	}
	.lists>img{
		width: 275px;
		height: 575px;
		box-shadow: 0 0 10px #c7c7c7;
		border-radius: 20px;
	}
	.lists{
		position: relative;
		overflow: hidden;
	}
	.container{
		display: flex;
		padding: 30px;
		justify-content: space-between;
	}
</style>
