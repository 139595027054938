<template>

	<!-- 移动model -->
	<div v-if="removeModelShow" class="mbm_move_model">
		<div class="mbm_move_model_center">
			<div class="mbm_move_model_center_header">
				将当前文件夹移动至“{{removeCurrentName}}”
			</div>

			<div class="mbm_move_model_center_body">
				<div>
					<removeFloader :remove_floader_data="allData" :currentId="parseInt(removeCurrentId)"
						:currentIndex="parseInt(removeCurrentIndex)" :handleIndex="parseInt(handleIndex)"
						:handleId="parseInt(handleId)" :noSelectList="noSelectList">
					</removeFloader>

				</div>

			</div>
			<div class="mbm_move_model_center_footer">
				<div class="mbm_move_model_center_footer_left">
					<div class="mbm_move_model_center_footer_btn" @click.stop="newChild"><span
							class="mbm-iconfont mbm-jia" style="font-size: 14px;"></span><span>新建目录</span></div>
				</div>
				<div class="mbm_move_model_center_footer_right">
					<button class="mbm_move_model_center_footer_btn" @click.stop="closeRemoveModel">取消</button>
					<button class="mbm_move_model_center_footer_btn mbm_move_define"
						@click.stop="removeCatalogue">确定</button>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	import removeFloader from '/src/components/removeFloader.vue';
	import bus from '/src/assets/js/bus.js';
	export default {
		name: 'removeModel',
		components: {
			removeFloader
		},
		data() {
			return {
				removeCurrentId: 0,
				removeCurrentIndex: 1,
				removeCurrentName: '全部活码',
				allData: this.formatData(this.floader_data),
				childList: [],
				
			}
		},
		props: {

			removeModelShow: Boolean,
			handleIndex: Number,
			handleId: Number,
			noSelectList: Object,
			floader_data: Array,
		},
		created() {

			// console.log(this.noSelectList)
			//监听当前选中的文件夹
			bus.$on('removeCatalogueChange', e => {
				this.removeCurrentId = parseInt(e.id);
				this.removeCurrentIndex = parseInt(e.index);
				this.removeCurrentName = e.name;
			});


		},
		methods: {

			// 取消数据数据双向绑定
			formatData(groupList) {
				if (groupList !== undefined && groupList !== null) {
					return JSON.parse(JSON.stringify(groupList))
				} else {
					return []
				}
			},
			//新建子目录
			newChild(e) {

				let index = this.removeCurrentIndex;
				let id = this.removeCurrentId;
				this.$emit('newChild',{index:index,id:id});
			},
			//关闭移动目录model
			closeRemoveModel() {
				
				this.$emit('cancel');
			},

			//移动文件夹
			removeCatalogue() {

				let currentIndex = this.handleIndex;
				let currentId = this.handleId;

				let toIndex = this.removeCurrentIndex;
				let toId = this.removeCurrentId;
				this.$emit('confirm', {
					current: {
						index: currentIndex,
						id: currentId
					},
					target: {
						index: toIndex,
						id: toId
					}
				});
			}
		},
	}
</script>

<style>
	
</style>
