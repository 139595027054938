import axios from "axios";
import Qs from "qs";
import md5 from "md5";
import OSS from "ali-oss";
import config from "./config.js";
import * as qiniu from 'qiniu-js';
import baseFunc from "./base_func.js";
import { v4 as uuidv4 } from 'uuid';

// 判断传入参数的类型，以字符串的形式返回
function dataType(obj) {
	if (obj === null) return "Null";
	if (obj === undefined) return "Undefined";
	return Object.prototype.toString.call(obj).slice(8, -1);
}

// 处理对象参数值，排除对象参数值为”“、null、undefined，并返回一个新对象
function dealObjectValue(obj) {
	let param = {};
	if (obj === null || obj === undefined || obj === "") return param;
	for (let key in obj) {
		if (dataType(obj[key]) === "Object") {
			param[key] = dealObjectValue(obj[key]);
		} else if (obj[key] !== null && obj[key] !== undefined && obj[key] !== "") {
			param[key] = obj[key];
		}
	}
	return param;
}
/*获取请求api token*/
function getApiAuthorization(){
	const t = (Date.parse(new Date())/1000).toString(16).toLowerCase();
	return md5(t+"_start_"+config.apiAppid+"_mbm_"+config.apiAppkey+"_end_"+t).toLowerCase()+"-"+t;
}

/* post请求头设置 */
axios.defaults.baseURL = config.baseMtURL;
axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

axios.defaults.withCredentials = false;
axios.defaults.timeout = 30000;

// 请求拦截
axios.interceptors.request.use(
	config => {
		//解决axios post请求后台获取不到参数问题
		if (config.method === 'post') {
			config.headers['Authorization'] = getApiAuthorization();
		};
		return config;
	},
	error => {
		return Promise.error(error);
	}
);

// 响应拦截
axios.interceptors.response.use(
	response => {
		// 返回状态码200，请求成功，否则抛出异常
		if (response.data.code === 200) {
			return Promise.resolve(response);
		} else {
			return Promise.reject(response);
		}
	},
	// 服务器状态码不是200的情况,这些自定义（需要与后台商量返回）
	error => {
		return Promise.reject(error)
	}
);
const apiAxios = {
	/* Post请求*/
	post(url, params){
		return axios({
			method: "post",
			url,
			data: Qs.stringify(dealObjectValue(params)),
			headers: {
				"Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
			}
		}).then(res => {
			return Promise.resolve(res);
		}).catch(err => {
			return Promise.reject(err);
		});
	},
	sendBeacon(url,params){
		const formData = new FormData();
		Object.keys(params).forEach((key) => {
		  let value = params[key];
		  if (typeof value !== 'string') {
		    // formData只能append string 或 Blob
		    value = JSON.stringify(value);
		  }
		  formData.append(key, value);
		});
		formData.append("_token", getApiAuthorization());
		return window.navigator.sendBeacon(config.baseMtURL+url, formData);
	},
	/*PostJSON请求*/
	postJson(url, params){
		return axios({
			method: "post",
			url,
			data: dealObjectValue(params),
			headers: {
				"Content-Type": "application/json",
			}
		}).then(res => {
			return Promise.resolve(res);
		}).catch(err => {
			return Promise.reject(err);
		});
	},
	/*get*/
	get(url, data){
		return axios({
			method: "get",
			url,
			params: dealObjectValue(data),
			headers: {}
		}).then(res => {
			return Promise.resolve(res);
		}).catch(err => {
			return Promise.reject(err);
		});
	},
	// 上传图片
	upPic(url, params){
		let config = {
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		};
		return axios({
			method: "post",
			url,
			data: dealObjectValue(params),
			config: config
		}).then(res => {
			return Promise.resolve(res.data);
		}).catch(err => {
			return Promise.reject(err.data);
		});
	},
	// 上传文件
	uploadFile(url, file,extra,progress){
		const formData = new FormData()
		for(let item in extra){
			formData.append(item, extra[item])
		}
		formData.append('file', file)
		return axios({
			method: "post",
			url,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			onUploadProgress:function(progressEvent){
				progressEvent.percent = 100*progressEvent.loaded/progressEvent.total | 0
				progressEvent.extra_data_defult = extra;
				progress&&progress(progressEvent)
			},
		}).then(res => {
			res.data.extra_data_defult = extra;
			return Promise.resolve(res.data);
		}).catch(err => {
			err.data.extra_data_defult = extra;
			return Promise.reject(err.data);
		});
	},
	// 加入报告
	joinTheReport(url, params, headerParams){
		const headers = Object.assign({
			'Content-Type': 'multipart/form-data',
		}, headerParams);
		return axios({
			method: "post",
			url,
			data: params,
			headers: headers
		}).then(res => {
			return Promise.resolve(res);
		}).catch(err => {
			return Promise.reject(err);
		});
	},
	// 加入报告 Json
	joinTheReportJson(url, params, headerParams){
		const headers = Object.assign({
			"Content-Type": "application/json",
		}, headerParams);
		return axios({
			method: "post",
			url,
			data: params,
			headers: headers
		}).then(res => {
			return Promise.resolve(res);
		}).catch(err => {
			return Promise.reject(err);
		});
	},
	//上传媒体文件到七牛云
	uploadMediaToQiniu(_this,file,progress,complete,extra){
		const self = this;
		return new Promise(function(resolve, reject){
			if(!file){
				reject({
					code:10001,
					message:'请选择音视频文件',
					extra:extra
				})
			};
			let media_types = file.type.split("/");
			let upload_type = extra && typeof extra.upload_type!='undefined' && extra.upload_type?extra.upload_type:'media';
			let checkExt = baseFunc.checkFileExt(baseFunc.getType(file.name,true),upload_type);
			if(checkExt){
				_this.$message.error(checkExt);
				reject({
					code:10001,
					message:checkExt,
					extra:extra
				})
				return
			}else{
				if(upload_type=="video" && media_types[0]!="video"){
					_this.$message.error("请上传视频文件");
					reject({
						code:10001,
						message:'请上传视频文件',
						extra:extra,
					})
					return
				}else if(upload_type=="audio" && media_types[0]!="audio"){
					_this.$message.error("请上传音频文件");
					reject({
						code:10001,
						message:'请上传音频文件',
						extra:extra,
					})
					return
				}else if(media_types[0]!="audio" && media_types[0]!="video"){
					_this.$message.error("请上传正确的音视频文件");
					reject({
						code:10001,
						message:'请上传正确的音视频文件',
						extra:extra,
					})
					return
				}
			}
			if(!_this.qiniu_token){
				self.post("/file/getQiniuToken",{}).then(res => {
					if(res.data.code==200){
						_this.qiniu_token = res.data.uploadToken;
						doUpload();
					}else{
						reject({
							code:10001,
							message:"上传失败",
							extra:extra,
						})
						return
					}
				}).catch(err => {
					reject({
						code:10001,
						message:"上传失败",
						extra:extra,
					})
					return
				});
			}else{
				doUpload();
			}
			function doUpload(){
				let key = "mbm_test/"+baseFunc.getDateString()+baseFunc.gen_uuid()+baseFunc.getType(file.name); // 文件的名称可能会出现中文，用bs64编码
				// 上传时的配置
				var qiniuConfig = {
					useCdnDomain: true, // 表示是否为CDN加速，true加速；false不加速
					region: qiniu.region.z2, // 当前地区， 华南区域
					domain: "http:"+config.qiniuMediaDomain // 七牛云注册的空间域名
				};
				//  设置文件的配置
				var putExtra = {
					fname: "", // 文件原文件名
					params: {}, // 放置自定义变量
					mimeType: null // 上传文件限制，null为不限制；限制类型放在数组中['image/png',"image/gif"]
				};
				//   实例化七牛云的上传实例
				var observable = qiniu.upload(file, key, _this.qiniu_token, putExtra, qiniuConfig);
				//   设置实例的监听对象-开始上传
				observable.subscribe({
					next(res) {
						// 上传进度
						res.total.extra = extra
						progress&&progress(res.total)
					},
					// 接收上传错误信息
					error(err) {
					  reject({
							code:10001,
							message:"上传失败",
							extra:extra,
							err:err
						})
					},
					// 接收上传完成后的信息
					complete(res) {
						var mediaData = {
							id: 0,/*附件id*/
							fileId: 0,/*附件id*/
							cover_img: "",/*封面图地址*/
							duration: 0,/*媒体时长*/
							height: 0,/*媒体高度*/
							width: 0,/*媒体宽度*/
							file_code: 1,
							file_status: 2,
							media_status: "1",
							file_openid: baseFunc.genID(8),/*随机数*/
							filename: file.name,/*文件名*/
							filesize: baseFunc.converSize(file.size),/*文件大小*/
							filesize_byte: file.size,/*文件大小*/
							key: res.key,/*媒体key*/
							old_transcode_url: config.qiniuMediaDomain+'/'+res.key,/*原始文件*/
							url: config.qiniuMediaDomain+'/'+res.key,/*原始文件*/
							transcode_url: config.qiniuMediaDomain+'/'+res.key,/*高清地址*/
							low_transcode_url: config.qiniuMediaDomain+'/'+res.key,/*低清地址*/
							media_type: file.type,/*源文件类型*/
							t_media_type: file.type,/*转码后文件类型*/
							media_types_a: media_types[0],
							media_types_b: media_types[1],
							residue_available: 5,
							type: media_types[0],
						}
						if(res.avinfo&&res.avinfo.audio){
							mediaData.duration = parseInt(res.avinfo.audio.duration);
						}
						if(res.avinfo&&res.avinfo.video){
							mediaData.width = res.avinfo.video.width;
							mediaData.height = res.avinfo.video.height;
							if(res.avinfo.video.duration){
								mediaData.duration = parseInt(res.avinfo.video.duration);
								submitTranscode(mediaData,extra)
							}else{
								submitTranscode(mediaData,extra)
							}
						}else{
							submitTranscode(mediaData,extra)
						}
					}
				});
			}
			
			function submitTranscode(mediaData,extra){
				complete&&complete(mediaData,extra);
				if(extra){
					Object.assign(mediaData, extra)
				}
				/*提交数据到后台*/
				self.post("/file/setAttachStatisticalRecord",mediaData).then(res => {
					if(res.data.code==200){
						mediaData.id=res.data.id;
						mediaData.width=res.data.width;
						mediaData.height=res.data.height;
						mediaData.duration=res.data.duration;
						resolve(mediaData);
					}else{
						reject({
							code:10001,
							message:"处理失败",
							extra:extra,
						})
					}
				}).catch(err => {
					reject({
						code:10001,
						message:"处理失败",
						extra:extra,
					})
				});
			}
		})
	},
	//上传媒体文件到阿里云oss
	uploadFileToAliOss(_this,file,progress,complete,extra){
		let client = new OSS(config.aliOSS);
		const self = this;
		return new Promise(function(resolve, reject){
			let fileName = "";
			if(!file){
				reject({
					code:10001,
					message:'请选择文件',
					extra:extra
				})
				return
			};
			let media_types = file.type.split("/");
			let checkExt = baseFunc.checkFileExt(baseFunc.getType(file.name,true),'img');
			if(checkExt){
				_this.$message.error(checkExt);
				reject({
					code:10001,
					message:checkExt,
					extra:extra
				})
				return
			}else if(media_types[0]!="image"){
				_this.$message.error("请上传正确的图片文件");
				reject({
					code:10001,
					message:'请上传正确的图片文件',
					extra:extra
				})
				return
			}
			let key = "mbm_test/"+baseFunc.getDateString()+uuidv4()+baseFunc.getType(file.name); // 文件的名称可能会出现中文，用bs64编码
			client.multipartUpload(key, file,{
					progress: function (p) { //获取进度条的值
						progress&&progress({
							percent:p*100,
							extra:extra
						})
					},
				}).then(result => {
					submitAttachRecord({
						"type":'img',
						"media_type": file.type,/*源文件类型*/
						"media_types_a": media_types[0],
						"media_types_b": media_types[1],
						"url": config.aliOSS.outDomain+"/"+result.name,
						"path": config.aliOSS.outDomain+"/"+result.name,
						"width": 0,
						"height": 0,
						"key":result.name,
						"filename": file.name,
						"filesize": baseFunc.converSize(file.size),
						"filesize_byte": file.size,
						"id": 0
					},extra)
				}).catch(err => {
					reject({
						code:10001,
						message:'上传失败',
						extra:extra,
						err:err
					})
				})
			/*提交后台-记录附件*/
			function submitAttachRecord(fileInfo,extra){
				complete&&complete(fileInfo);
				if(extra){
					Object.assign(mediaData, extra)
				}
				/*提交数据到后台*/
				self.post("/file/setAttachStatisticalRecord",fileInfo).then(res => {
					if(res.data.code==200){
						fileInfo.id=res.data.id;
						fileInfo.width=res.data.width;
						fileInfo.height=res.data.height;
						fileInfo.duration=res.data.duration;
						resolve(fileInfo);
					}else{
						reject({
							code:10001,
							message:"上传失败",
							extra:extra,
						})
					}
				}).catch(err => {
					reject({
						code:10001,
						message:"上传失败",
						extra:extra,
					})
				});
			}
		})
	}
}

export default  apiAxios  /*调用 import A from "request",如果要使用里面的方法，需要  A.get()*/
// export default  {apiAxios}  /*调用 import A from "request",如果要使用里面的方法，需要  A.apiAxios.get()*/