<template>
<a-config-provider :locale="lang">
	<div class="iframe_wrap" :style="style.iframe">
		<img class="img" src="/img/top_black2@2x.png">
		<div class="iframe_border">
			<!-- <iframe ref="iframe" src="http://192.168.0.102:8080/preview.html?h=1" :style="iframeAutoHeight"></iframe> -->
			<iframe :src="settingsData.qrcode_info.preview_url?settingsData.qrcode_info.preview_url:'about:blank'" :style="iframeAutoHeight"></iframe>
		</div>
	</div>
	<div class="settings" v-if="show.settings" :style="style.settings">
		<div class="settings-title" v-if="show.settings_qrcode">
			<p class="img" v-if="settingsData.qrcode_info.type==1"><img :src="settingsData.qrcode_info.path" /></p>
			<p class="link" v-if="settingsData.qrcode_info.type==2"><span>{{settingsData.qrcode_info.path}}</span><input type="text" ref="link_text" style="position: absolute;z-index:-1;opacity:0;" :value="settingsData.qrcode_info.path"> <span class="link_copy" @click="linkCopy">复制</span></p>
			<p class="title">
				<span contenteditable="plaintext-only" ref="titleedit" @blur="editTitle">{{settingsData.qrcode_info.title}}</span>
				<svg @click="focusTitle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" data-icon="edit" fill="currentColor" aria-hidden="true"><path d="M257.7 752c2 0 4-.2 6-.5L431.9 722c2-.4 3.9-1.3 5.3-2.8l423.9-423.9c3.9-3.9 3.9-10.2 0-14.1L694.9 114.9c-1.9-1.9-4.4-2.9-7.1-2.9s-5.2 1-7.1 2.9L256.8 538.8c-1.5 1.5-2.4 3.3-2.8 5.3l-29.5 168.2c-1.9 11.1 1.5 21.9 9.4 29.8 6.6 6.4 14.9 9.9 23.8 9.9zm67.4-174.4L687.8 215l73.3 73.3-362.7 362.6-88.9 15.7 15.6-89zM880 836H144c-17.7 0-32 14.3-32 32v36c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-36c0-17.7-14.3-32-32-32z"></path></svg>
			</p>
		</div>
		<div class="settings-list" :class="show.settings_list?'':'closed'">
			<p class="settings-list_title"><span @click="toggleSettings"></span>二维码设置</p>
			<div class="settings-list_item" v-if="show.settings_list_expire">
				<div><span @click="toggleModal(popupExpire)">修改</span>查看有效期</div>
				<p v-for="item,key in popupExpire.effect" :class="item.active==settingsData.set_config[key]?'active':''" v-html="item.options[settingsData.set_config[key]]"></p>
				<a-modal title="查看有效期设置" :maskClosable="false" width="560px" centered @ok="popupOk(popupExpire)" @cancel="toggleModal(popupExpire)" :visible="popupExpire.show">
					<div class="popup-item">
						<div class="popup-item_row">
							<label class="popup-item_row_label">当前有效期</label>
							<div class="popup-item_row_content-inline">
								<div>
									<a-select v-model:value="settingsData.set_config.time" style="min-width:8em;" @change="popupExpire.genTimeRange">
										<a-select-option :value="0">长期有效</a-select-option>
										<a-select-option :value="1">一天</a-select-option>
										<a-select-option :value="3">三天</a-select-option>
										<a-select-option :value="180">六个月</a-select-option>
										<a-select-option :value="365">一年</a-select-option>
										<a-select-option :value="-1">自定义</a-select-option>
									</a-select>
								</div>
								<div class="addition">
									<p v-show="[1,3,180,365].includes(settingsData.set_config.time)">{{popupExpire.addtionText}}</p>
									<a-range-picker v-show="settingsData.set_config.time==-1" @change="popupExpire.customDate" v-model:value="popupExpire.customDateRange" />
								</div>
							</div>
						</div>
						<p class="popup-item_tips">* 你可随时重新设置 查看有效期<br>* 到期后二维码不会被删除，但扫码会显示该二维码已到期，内容无法查看。</p>
						<p class="popup-item_tips"></p>
					</div>
				</a-modal>
			</div>
			<div class="settings-list_item" v-if="show.settings_list_auth">
				<div><span @click="popupVerificate.set()">设置</span>高级密码</div>
				<p v-for="item,key in popupVerificate.effect" :class="item.active==settingsData.set_config[key]?'active':''" v-html="item.options[settingsData.set_config[key]]"></p>
			</div>
			<div class="settings-list_item" v-if="show.settings_list_auth">
				<div><span @click="toggleModal(popupAuth)">修改</span>查看权限</div>
				<p v-for="item,key in popupAuth.effect" :class="item.active==settingsData.set_config[key]?'active':''" v-html="item.options[settingsData.set_config[key]]"></p>
				<a-modal title="查看权限设置" :maskClosable="false" width="560px" centered @ok="popupOk(popupAuth)" @cancel="toggleModal(popupAuth)" :visible="popupAuth.show">
					<div class="popup-item">
						<div class="popup-item_row">
							<label class="popup-item_row_label">查看权限</label>
							<div class="popup-item_row_content">
								<div>
									<a-radio-group name="radioGroup" v-model:value="settingsData.set_config.encryption">
										<a-radio :value="2">任何人</a-radio>
										<a-radio :value="1">加密</a-radio>
									</a-radio-group>
								</div>
								<div class="addition" v-show="settingsData.set_config.encryption==1">
									<a-input-group compact>
										<a-input style="width:30%" placeholder="请填写密码提示" v-model:value="settingsData.set_config.psw_tip" />
										<a-input style="width:65%" placeholder="请输入4-20位密码（仅限数字或字母）" v-model:value="settingsData.set_config.password" />
									</a-input-group>
								</div>
							</div>
						</div>
					</div>
					<div class="popup-item">
						<div class="popup-item_row">
							<label class="popup-item_row_label">访问时间</label>
							<div class="popup-item_row_content">
								<div>
									<a-radio-group name="radioGroup" v-model:value="settingsData.set_config.time_horizon">
										<a-radio :value="2">不限制</a-radio>
										<a-radio :value="1">限制时间</a-radio>
									</a-radio-group>
								</div>
								<div class="addition" v-show="settingsData.set_config.time_horizon==1">
									每天 <a-time-picker format="HH:mm" v-model:value="popupAuth.timeFormat.start_time_quantum" @change="popupAuth.timeChange('start_time_quantum')" />
									- <a-time-picker format="HH:mm" v-model:value="popupAuth.timeFormat.end_time_quantum" @change="popupAuth.timeChange('end_time_quantum')" />
								</div>
							</div>
						</div>
					</div>
				</a-modal>
			</div>
			<div class="settings-list_item" v-if="show.settings_list_open">
				<div><span @click="toggleModal(popupOpen)">修改</span>手机应用打开方式</div>
				<p v-for="item,key in popupOpen.effect" :class="item.active===true||item.active==item.calc()?'active':''" v-html="item.options[item.calc()]"></p>
				<a-modal title="二维码打开方式设置" :maskClosable="false" width="935px" centered @ok="popupOk(popupOpen)" @cancel="toggleModal(popupOpen)" :visible="popupOpen.show">
					<div class="opensetting-openselect">
						<div>
							<p><a-checkbox v-model:checked="popupOpen.wechat.checkall" :indeterminate="popupOpen.wechat.indeterminate" @change="openChange('chat_app')">允许微信打开</a-checkbox></p>
							<a-checkbox-group v-model:value="popupOpen.wechat.value" @change="openChange('chat_app_option')" style="padding-left:24px;">
								<p><a-checkbox value="scan">允许“扫一扫”查看</a-checkbox></p>
								<p><a-checkbox value="press">允许“长按识别二维码”查看</a-checkbox></p>
								<p><a-checkbox value="share">允许“转发分享”查看</a-checkbox></p>
							</a-checkbox-group>
						</div>
						<div>
							<p><a-checkbox v-model:checked="popupOpen.other" @change="openChange('other_app')">允许微信以外其他应用打开</a-checkbox></p>
							<p style="padding-left:24px;">手机浏览器、支付宝、QQ、今日头条等带扫码功能的应用</p>
						</div>
					</div>
					<a-spin :spinning="popupOpen.spinning">
					<div class="opensetting-opentype">
						<h2>微信扫码后的展示方式</h2>
						<a-radio-group class="opensetting-opentype_box" v-model:value="settingsData.set_config.mode">
							<div class="opensetting-opentype_box_item">
								<a-radio :value="1">
									<div class="item">
										<img src="/img/logo.png">
										<p><strong>提片小程序</strong>有1-2秒的提片logo等待页<br/>支持收藏二维码到小程序</p>
									</div>
								</a-radio>
								<div class="sample" @click="popupOpen.sample.chat_img=true">查看示例</div>
								<a-modal :visible="popupOpen.sample.chat_img" centered :footer="null" @cancel="popupOpen.sample.chat_img=false" width="260px" :bodyStyle="{'text-align':'center'}">
									<img :src="settingsData.attach_files.chat_img" style="width:100%;">
								</a-modal>
							</div>
							<div class="opensetting-opentype_box_item">
								<a-radio :value="2" @click.prevent="checkVip(2,settingsData.set_config,'mode',popupOpen,'mode_message')">
									<div class="item">
										<img src="../assets/imgs/chat.png">
										<p><strong>无标识小程序</strong>扫码加载时不显示提片标识<br />转发分享不显示提片标识<br />支持微信下拉返回小程序</p>
									</div>
									<div class="vip">VIP专属</div>
								</a-radio>
								<div class="sample" @click="popupOpen.sample.no_chat_img=true">查看示例</div>
								<a-modal :visible="popupOpen.sample.no_chat_img" centered :footer="null" @cancel="popupOpen.sample.no_chat_img=false" width="260px" :bodyStyle="{'text-align':'center'}">
									<img :src="settingsData.attach_files.no_chat_img" style="width:100%;">
								</a-modal>
							</div>
							<div class="opensetting-opentype_box_item">
								<a-radio :value="3" @click.prevent="checkVip(3,settingsData.set_config,'mode',popupOpen,'mode_message')">
									<div class="item">
										<img src="../assets/imgs/h5.png">
										<p><strong>H5网页打开</strong>支持分享朋友圈<br />支持外链直接跳转<br />文章中的二维码可长按识别</p>
									</div>
									<div class="vip">VIP专属</div>
								</a-radio>
								<div class="sample" @click="popupOpen.sample.h5_img=true">查看示例</div>
								<a-modal :visible="popupOpen.sample.h5_img" centered :footer="null" @cancel="popupOpen.sample.h5_img=false" width="260px" :bodyStyle="{'text-align':'center'}">
									<img :src="settingsData.attach_files.h5_img" style="width:100%;">
								</a-modal>
							</div>
						</a-radio-group>
						<div class="opensetting-opentype_defaultset"><a-checkbox v-model:checked="popupOpen.globalmode">新增的二维码默认采用此设置</a-checkbox></div>
						<div class="opensetting-opentype_tips">*切换打开方式后，二维码图案会更新，需要重新下载使用，老的二维码依然可以用原来的方式打开。</div>
					</div>
					</a-spin>
				</a-modal>
			</div>
			<div class="settings-list_item" v-if="show.settings_list_bot">
				<div><span @click="toggleModal(popupBottom)">修改</span>底部设置<i>（对所有二维码有效）</i></div>
				<p v-for="item,key in popupBottom.effect" :class="item.active==settingsData.footer_set_config[key]?'active':''" v-html="item.options[settingsData.footer_set_config[key]]"></p>
				<a-modal title="底部设置" :maskClosable="false" width="560px" centered @ok="popupOk(popupBottom)" @cancel="toggleModal(popupBottom)" :visible="popupBottom.show" class="bottomsetting">
					<div class="bottomsetting-item">
						<div>
							<p>收藏功能</p>
							<p>扫码者可以收藏你的二维码，方便快捷查看</p>
						</div>
						<a-radio-group v-model:value="settingsData.footer_set_config.colloct">
							<a-radio :value="1">开启</a-radio>
							<a-radio :value="2">关闭</a-radio>
						</a-radio-group>
					</div>
					<div class="bottomsetting-item">
						<div>
							<p>分享功能</p>
							<p>扫码者可以把你的二维码分享给好友或朋友圈</p>
						</div>
						<a-radio-group v-model:value="settingsData.footer_set_config.share">
							<a-radio :value="1">开启</a-radio>
							<a-radio :value="2">关闭</a-radio>
						</a-radio-group>
					</div>
					<div class="bottomsetting-item">
						<div>
							<p>二维码扫码量</p>
							<p>用户扫码后可以在左下角看到二维码扫码量</p>
						</div>
						<a-radio-group v-model:value="settingsData.footer_set_config.view_tag">
							<a-radio :value="1">开启</a-radio>
							<a-radio :value="2">关闭</a-radio>
						</a-radio-group>
					</div>
					<div class="bottomsetting-item">
						<div>
							<p>投诉功能和提片网底部标识</p>
							<p>免费版为开启状态，升级为任一付费版本均可自动关闭</p>
						</div>
						<a-radio-group v-model:value="settingsData.footer_set_config.footer_tag" :disabled="true">
							<a-radio :value="1">开启</a-radio>
							<a-radio :value="2">关闭</a-radio>
							<p style="margin-top:.5em;">(付费版本功能，<a :href="settingsData.qrcode_info.upgrade_url" target="_blank">升级版本</a>)</p>
						</a-radio-group>
					</div>
				</a-modal>
			</div>
			<div class="settings-list_item" v-if="show.settings_list_video">
				<div><span @click="toggleModal(popupVideo)">修改</span>视频下载</div>
				<p v-for="item,key in popupVideo.effect" :class="item.active==videoDownloadSetMerge()?'active':''" v-html="item.options[videoDownloadSetMerge()]"></p>
				<a-modal title="视频下载设置" :maskClosable="false" width="560px" centered @ok="popupOk(popupVideo)" @cancel="toggleModal(popupVideo)" :visible="popupVideo.show">
					<a-spin :spinning="popupVideo.spinning">
					<div class="popup-item">
						<div class="popup-item_row">
							<label class="popup-item_row_label">全局设置</label>
							<div class="popup-item_row_content-inline">
								<div>
									<a-radio-group v-model:value="settingsData.global_set_config.download_tag">
										<a-radio :value="1" @click.prevent="checkVip(1,settingsData.global_set_config,'download_tag',popupVideo,'media_download_message')">允许下载</a-radio>
										<a-radio :value="2">关闭下载</a-radio>
									</a-radio-group>
								</div>
								<p class="addition-tips">所有二维码内视频有效</p>
							</div>
						</div>
					</div>
					<div class="popup-item">
						<div class="popup-item_row">
							<label class="popup-item_row_label">独立设置</label>
							<div class="popup-item_row_content-inline">
								<div>
									<a-radio-group v-model:value="settingsData.set_config.video_download_code">
										<a-radio :value="1" @click.prevent="checkVip(1,settingsData.set_config,'video_download_code',popupVideo,'media_download_message')">允许下载</a-radio>
										<a-radio :value="2">关闭下载</a-radio>
									</a-radio-group>
								</div>
								<p class="addition-tips">当前二维码内视频有效</p>
							</div>
						</div>
					</div>
					<div class="popup-tips">全局设置不会改变独立设置，独立设置保持最高优先级，如无特殊需要，不要使用独立设置</div>
					</a-spin>
				</a-modal>
			</div>
		</div>
	</div>
</a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import apiAxios from '../config/request.js';
import baseFunc from '../config/base_func.js';
import config from '../config/config.js';
import moment from 'moment';
import { message } from 'ant-design-vue';
const $$$=console.log;
export default {
		name:'settings',
		props:{
			styleConfig:Object,
			showConfig:Object,
			idKey:String
		},
		created(){
		},
		mounted(){
			this.setInit();
		},
		data(){
			let default_show={
				iframe:true,
				settings:true,
				settings_qrcode:true,
				settings_list:true,
				settings_list_expire:true,
				settings_list_auth:true,
				settings_list_open:true,
				settings_list_bot:true,
				settings_list_video:true
			};
			return {
				lang:zhCN,
				show:Object.assign(default_show,this.showConfig),
				iframeAutoHeight:{},
				// style:JSON.parse(JSON.stringify(this.styleConfig)),
				style:JSON.parse(JSON.stringify(Object.assign({},this.styleConfig))),
				//是否有权限设置VIP选项
				vipCheck:false,
				//设置参数数据
				settingsData:{
					footer_set_config:{},
					global_set_config:{},
					set_config:{},
					qrcode_info:{}
				},
				//设置参数备份(点击取消时重置)
				settingsData_original:{
					footer_set_config:{},
					global_set_config:{},
					set_config:{},
					qrcode_info:{},
					skip:{},
				},
				//有效期
				popupExpire:{
					show:false,
					effect:{
						time:{active:0,options:{
							'-1':'自定义',
							0:'长期有效',
							1:'一天',
							3:'三天',
							180:'六个月',
							365:'一年'}
						},
					},
					addtionText:'长期有效',
					customDateRange:[],
					customDate:()=>{
						let _self=this.popupExpire;
						let config=this.settingsData.set_config;
						config.start_time=_self.customDateRange[0].format('YYYY/MM/DD');
						config.end_time=_self.customDateRange[1].format('YYYY/MM/DD');
						_self.effect.time.options[-1]=`自定义 ${config.start_time} ~ ${config.end_time}`;
					},
					genTimeRange:()=>{
						let _self=this.popupExpire;
						let config=this.settingsData.set_config;
						let date=new Date(),
							start_time=`${date.getFullYear()}/${date.getMonth()+1}/${date.getDate()}`,
							end_time=0;
						switch(config.time){
							case 0:
								start_time=0;
								end_time=0;
								break;
							case 1:
								date.setDate(date.getDate()+1);
								end_time=`${date.getFullYear()}/${date.getMonth()+1}/${date.getDate()}`;
								_self.effect.time.options[1]=`${end_time} 后二维码内容将无法查看`;
								break;
							case 3:
								date.setDate(date.getDate()+3);
								end_time=`${date.getFullYear()}/${date.getMonth()+1}/${date.getDate()}`;
								_self.effect.time.options[3]=`${end_time} 后二维码内容将无法查看`;
								break;
							case 180:
								date.setMonth(date.getMonth()+6);
								end_time=`${date.getFullYear()}/${date.getMonth()+1}/${date.getDate()}`;
								_self.effect.time.options[180]=`${end_time} 后二维码内容将无法查看`;
								break;
							case 365:
								date.setFullYear(date.getFullYear()+1);
								end_time=`${date.getFullYear()}/${date.getMonth()+1}/${date.getDate()}`;
								_self.effect.time.options[365]=`${end_time} 后二维码内容将无法查看`;
								break;
							case -1:
								if(config.start_time)start_time=config.start_time;
								end_time=config.end_time?config.end_time:start_time;
								_self.customDateRange=[moment(start_time,'YYYY/MM/DD'),moment(end_time,'YYYY/MM/DD')];
								_self.effect.time.options[-1]=`自定义 ${start_time} ~ ${end_time}`;
								break;
						}
						config.start_time=start_time;
						config.end_time=end_time;
						_self.addtionText=`从现在开始到 ${end_time} (截止当天23:59:59)`;
					},
					init:()=>{
						this.popupExpire.genTimeRange();
					}
				},
				popupVerificate:{//高级密码
					show:false,
					effect:{
						verificate:{active:1,options:{1:'已设置高级密码',0:'未设置'}},
					},
					set:()=>{
						if(config.devEnv=='dev'){
							window.open('/verificate/detail.html?id_key='+this.idKey);
						}else{
							window.open(this.settingsData_original.skip.verificate_url);
						}
					}
				},
				//权限
				popupAuth:{
					show:false,
					effect:{
						encryption:{active:2,options:{1:'输入密码查看',2:'允许任何人查看'}},
						time_horizon:{active:2,options:{1:`允许每天??-??时段查看`,2:'允许任何时段查看'}}
					},
					timeFormat:{
						start_time_quantum:null,
						end_time_quantum:null
					},
					timeChange:(key)=>{
						this.settingsData.set_config[key]=this.popupAuth.timeFormat[key].format('HH:mm');
						this.popupAuth._genTimeRange();
					},
					_genTimeRange:()=>{
						let _self=this.popupAuth;
						let start_time_quantum=this.settingsData.set_config.start_time_quantum;
						let end_time_quantum=this.settingsData.set_config.end_time_quantum;
						_self.effect.time_horizon.options[1]=`允许每天 ${start_time_quantum?start_time_quantum:'??'}-${end_time_quantum?end_time_quantum:'??'} 时段查看`
					},
					init:()=>{
						let _self=this.popupAuth;
						let start_time_quantum=this.settingsData.set_config.start_time_quantum;
						let end_time_quantum=this.settingsData.set_config.end_time_quantum;
						_self.timeFormat.start_time_quantum=start_time_quantum?moment(start_time_quantum,'HH:mm'):null;
						_self.timeFormat.end_time_quantum=end_time_quantum?moment(end_time_quantum,'HH:mm'):null;
						_self._genTimeRange();
					}
				},
				//打开方式
				popupOpen:{
					show:false,
					spinning:false,
					effect:{
						opentype:{
							active:1,
							options:{1:'允许所有应用打开',2:'仅仅允许微信打开',3:'仅允许微信以外应用打开'},
							calc:()=>{
								if(this.settingsData.set_config.chat_app==1&&this.settingsData.set_config.other_app==1)return 1;
								if(this.settingsData.set_config.chat_app==1&&this.settingsData.set_config.other_app==2)return 2;
								if(this.settingsData.set_config.chat_app==2&&this.settingsData.set_config.other_app==1)return 3;
							}
						},
						showtype:{
							active:true,
							options:{1:'微信打开后用<span>提片小程序</span>展示',2:'微信打开后用<span>无标识小程序</span>展示',3:'微信打开后用<span>H5</span>展示'},
							calc:()=>{return this.settingsData.set_config.mode;}
						},
					},
					wechat:{
						options:['scan','press','share'],
						value:['scan','press','share'],
						indeterminate:false,
						checkall:true
					},
					sample:{
						chat_img:false,
						no_chat_img:false,
						h5_img:false,
					},
					other:true,
					globalmode:false,
				},
				//底部设置
				popupBottom:{
					show:false,
					effect:{
						colloct:{active:1,options:{1:'收藏功能',2:'不显示收藏功能'}},
						share:{active:1,options:{1:'分享功能',2:'不显示分享功能'}},
						view_tag:{active:1,options:{1:'显示扫码量',2:'不显示显示扫码量'}},
						footer_tag:{active:1,active:1,options:{1:'显示投诉和提片网底部标识',2:'不显示投诉和提片网底部标识'}}
					},
				},
				//视频下载
				popupVideo:{
					show:false,
					spinning:false,
					effect:{
						download_tag:{active:1,options:{1:'允许下载',2:'禁止下载'}}
					},
				},
				moment
			};
		},
		methods:{
			linkCopy(){
				this.$refs.link_text.select();
				document.execCommand('copy');
				message.success('复制成功');
			},
			/*初始化请求数据*/
			setInit(){
				apiAxios.post('/qrcode/set',{type:1,id_key:this.idKey}).then(res=>{
					this.settingsData_original=JSON.parse(JSON.stringify(res.data.data));
					this.settingsData=res.data.data;
					//部分弹窗初始化
					this.popupAuth.init();
					this.popupExpire.init();
					//type==2时为连接二维码，没有打开方式设置
					if(this.settingsData.qrcode_info.type==2){
						this.show.settings_list_open=false;
					}
					//
					// $$$(res.data.data.qrcode_info.media_number);
				});
				if(this.style.iframe.height=='auto'){
					window.addEventListener('message',(e)=>{
						if(e.data.type=='returnHeight'){
							this.iframeAutoHeight.height=e.data.height+'px';
							this.style.iframe.height='fit-content';
						}
					});
				}
			},
			toggleSettings(){
				this.show.settings_list=!this.show.settings_list;
			},
			toggleModal(pop){
				pop.show=!pop.show;
				this.settingsData=JSON.parse(JSON.stringify(this.settingsData_original));
			},
			focusTitle(){
				this.$refs.titleedit.focus();
			},
			editTitle(event){
				let val=event.target.textContent;
				if(val==this.settingsData.qrcode_info.title)return;
				let params={
					type:2,
					id_key:this.idKey,
					qrcode_info:JSON.stringify({title:val})
				};
				apiAxios.post('/qrcode/set',params).then(res=>{
					if(res.data.code==200){
						this.$message.success('设置更新成功',3);
						this.settingsData_original=JSON.parse(JSON.stringify(res.data.data));
						this.settingsData=res.data.data;
						this.$emit('getsetData',res.data.data)
					}else{
						baseFunc.checkState(res.data)
					}
				}).catch(err => {
					if(typeof err.data!="undefined"){
						baseFunc.checkState(err.data)
					}
				});
			},
			popupOk(pop){
				pop.show=!pop.show;
				//打开方式："全局新增二维码均采用此设置"特殊处理
				if(pop.hasOwnProperty('globalmode')&&pop.globalmode)this.settingsData.global_set_config.mode=this.settingsData.set_config.mode;
				let params={
					type:2,
					id_key:this.idKey,
					set_config:JSON.stringify(this.settingsData.set_config),
					global_set_config:JSON.stringify(this.settingsData.global_set_config),
					footer_set_config:JSON.stringify(this.settingsData.footer_set_config),
				};
				apiAxios.post('/qrcode/set',params).then(res=>{
					if(res.data.code==200){
						this.$message.success('设置更新成功',3);
						this.settingsData_original=JSON.parse(JSON.stringify(res.data.data));
						this.settingsData=res.data.data;
						this.$emit('getsetData',res.data.data)
					}else{
						baseFunc.checkState(res.data)
					}
				}).catch(err => {
					if(typeof err.data!="undefined"){
						baseFunc.checkState(err.data)
					}
				});
			},
			//打开方式：同步设置数据值
			openChange(name){
				switch(name){
					case 'chat_app':
						this.popupOpen.wechat.value=this.popupOpen.wechat.checkall?this.popupOpen.wechat.options:[];
						this.settingsData.set_config.chat_app=this.popupOpen.wechat.value.length?1:2;
						break;
					case 'other_app':
						this.settingsData.set_config.other_app=this.popupOpen.other?1:2;
						break;
				}
				let value=this.popupOpen.wechat.value;
				this.settingsData.set_config.scan=value.includes('scan')?1:2;
				this.settingsData.set_config.press=value.includes('press')?1:2;
				this.settingsData.set_config.share=value.includes('share')?1:2;
			},
			//视频下载：计算独立设置和全局设置合并后的视频下载最终值
			videoDownloadSetMerge(){
				let globalset=this.settingsData.global_set_config.download_tag;
				let indiset=this.settingsData.set_config.video_download_code;
				if(indiset)return indiset;
				if(globalset)return globalset;
				return 2;
			},
			//验证需要VIP权限的选项
			checkVip(val,settingOjb,settingKey,obj,msgKey){
				if(this.vipCheck==1){
					settingOjb[settingKey]=val;
				}else{
					obj.spinning=true;
					apiAxios.post('/user/getUserDefultSet',{}).then(res=>{
						obj.spinning=false;
						this.vipCheck=res.data.data.vip_status;
						if(this.vipCheck==1){
							settingOjb[settingKey]=val;
						}else{
							baseFunc.checkState({code:11001,message:res.data.data[msgKey]});
						}
					});
				}
			}
		},
		watch:{
			'popupOpen.wechat.value':function(val){
				this.popupOpen.wechat.checkall=val.length==this.popupOpen.wechat.options.length;
				this.popupOpen.wechat.indeterminate=!!val.length&&val.length<this.popupOpen.wechat.options.length;
			},
			idKey:{
			    handler(newVal,oldVal){
					if(newVal!=oldVal){
						this.setInit()
					}
			    },
			    deep:true// 深度监听父组件传过来对象变化
			}
		}
	}
	
</script>

<style>
.qrcode-set-warp-xj18sh2 .ant-modal-body{overflow-y: scroll;overflow-x: hidden;}
.qrcode-set-warp-xj18sh2 .qrcode-set-box-h18s{width:100%;margin:0 auto;display:flex;justify-content:space-between;height:700px;position: relative;}
.iframe_wrap{width:320px;padding:6px;box-shadow:0 0 5px 0 rgba(0,0,0,.2);position:sticky;border-radius:34px;margin:5px;top:5px;}
.iframe_wrap .img{position: absolute;width:100%;left:0;top:-2px;border-top-left-radius:38px;border-top-right-radius:38px;}
.iframe_wrap .iframe_border{width:100%;height:100%;overflow: hidden;border-radius:30px;border:1px solid #f3f3f3;}
.iframe_wrap .iframe_border iframe{width:100%;height:100%;border:none;display: block;}
.settings{width:500px;background: white;}
.settings-title {text-align: center;margin-top: 35px;margin:0 5%;}
.settings-title .img{width:130px;margin:5px auto 0 auto;box-shadow:1px 1px 5px rgba(0,0,0,.2);padding:5px;}
.settings-title .img img{width:100%;}
.settings-title .link{border:1px dashed #faedaf;background:#fffcee;color:#f58a00;border-radius: 5px;min-height:100px;display: flex;align-items: center;justify-content: center;font-weight:600;}
.settings-title .link_copy{margin-left: 10px;color:#f58a00;background:#ffe699;font-size:12px;border-radius:5px;width:35px;line-height:20px;text-align:center;display:inline-block;font-weight:normal;cursor: pointer;}
.settings-title .title{text-align: center;margin:15px 3% 32px 3%;padding:5px 0;color:#666;font-weight: bold;}
.settings-title .title span{padding:0 4px;}
.settings-title .title span:focus{outline:none;border-bottom:1px solid #999;}
.settings-title .title svg{width:20px;height:20px;vertical-align: middle;cursor: pointer;}
.settings-list{margin:0 5%;font-size: 14px;}
.settings-list_title{font-size: 16px;padding-bottom:10px;border-bottom: 1px solid #e9e9e9;margin:0;}
.settings-list_title span{float: right;color: #285af9;font-size:14px;cursor: pointer;margin-right:8px;}
.settings-list_title span::before{content:'收起'}
.settings-list_title span::after{border-left:1px solid #285af9;border-bottom: 1px solid #285af9;content:'';display: inline-block;width:8px;height:8px;transform:rotate(-225deg) translate(-1px,-5px);transition:transform .3s;margin-top:5px;overflow: hidden;}
.settings-list.closed .settings-list_title span::before{content:'展开'}
.settings-list.closed .settings-list_title span::after{transform: rotate(-45deg) translate(5px,1px);}
.settings-list.closed .settings-list_item{display: none;}
.settings-list_item{border-bottom: 1px solid #e9e9e9;padding:10px 0 10px 0;}
.settings-list_item div{margin-left: 22px;line-height: 32px;}
.settings-list_item div i{font-style: normal;color:#999;}
.settings-list_item div span{float: right;color:#285af9;cursor: pointer;}
.settings-list_item div span:hover{color:#426efa;}
.settings-list_item p{margin:0 0 0 22px;line-height:26px;position: relative;color:#666;}
.settings-list_item p::before{content:'';position: absolute;top:50%;margin-top:-3px;left:-15px;width: 6px;height: 6px;border-radius:50%;background: #cfd4dd;}
.settings-list_item p.active::before{background:#285af9;}
.settings-list_item p span{padding:3px 6px;background:#f0f0f0;border-radius: 4px;border:1px solid #d8d8d8;box-sizing: border-box;margin:0 .5em;}

.popup-item{margin-bottom:15px;}
.popup-item:last-child{margin-bottom: 0;}
.popup-item_row{display: flex;}
.popup-item_row_label{line-height:32px;min-width:100px;}
.popup-item_row_content{line-height: 32px;flex:1;}
.popup-item_row_content .addition p,.popup-item_row_content-inline .addition p{margin-bottom:0;}
.popup-item_row_content-inline{display: flex;line-height: 32px;}
.popup-item_row_content-inline .addition{padding-left:4px;}
.popup-item_row_content-inline .addition-tips{margin:0;color:#999;}
.popup-item_tips{padding-left: 100px;color:#999;margin:8px 0 0 0;font-size: 12px;}
.popup-tips{text-align: center;color:orange;font-size:12px;}

.opensetting-openselect{display: flex;}
.opensetting-openselect>div{flex: 1;}
.opensetting-openselect div p{margin-bottom:8px}
.opensetting-openselect>div:last-child p:last-child{font-size: 12px;color: #666;}
.opensetting-opentype{margin-top:32px;}
.opensetting-opentype h2{font-size: 18px;font-weight: bold;text-align: center;line-height:44px;}
.opensetting-opentype_box{display: flex !important;justify-content: space-between;margin-top:20px !important;}
.opensetting-opentype_box_item{width:32%;display: flex;flex-direction: column;}
.opensetting-opentype_box_item label{display: block;border-radius:6px;background:#F3F5F6;margin:0;border:2px solid transparent;position: relative;flex:1;}
.opensetting-opentype_box_item label.ant-radio-wrapper-checked{border-color:#167fe8;}
.opensetting-opentype_box_item label>span:first-child{position:absolute;right:.5em;top:.5em;}
.opensetting-opentype_box_item label>span:last-child{display: block;padding:14px;}
.opensetting-opentype_box_item label .item{display: flex;}
.opensetting-opentype_box_item label .item img{width:40px;height:40px;margin-right:.5em;}
.opensetting-opentype_box_item label .item p{margin:0;font-size: 14px;color:#666;}
.opensetting-opentype_box_item label .item p strong{font-size: 18px;color: #333;padding-bottom:4px;display: block;}
.opensetting-opentype_box_item label .vip{color:#f60;margin-top:8px;}
.opensetting-opentype_box_item .sample{text-align: right;color:#1c4da8;cursor: pointer;padding:3px;}
.opensetting-opentype_defaultset{text-align: right;margin-top:2em;}
.opensetting-opentype_tips{text-align:center;margin-top:2em;color: #666;}

.bottomsetting{}
.bottomsetting p{margin:0}
.bottomsetting-item{border-bottom: 1px solid #eee;display: flex;align-items: center;padding:1em 0;}
.bottomsetting-item:first-child{padding-top:0;}
.bottomsetting-item:last-child{border:none;padding-bottom:0;}
.bottomsetting-item div:first-child p:last-child,
.bottomsetting-item .ant-radio-wrapper,
.bottomsetting-item .ant-radio-group{font-size: 12px;}
.bottomsetting-item div:first-child p:last-child{color:#999;margin-top:.5em;}
.bottomsetting-item div:first-child{flex:1;}
.bottomsetting-item div:last-child{text-align: right;}
</style>