<template>
	<!-- <div class="vueBox"> -->
	<div class="mbm_videoManage_box">
		<div class="mbm_videoManage_box_topBar">
			<div class="mbm_videoManage_box_topBar_item"
				:class="{'mbm_videoManage_box_topBar_item_active':topChange==1}" data-index="1"
				@click="changeTop($event)">音频库</div>
			<div v-if="isPageOrModel==1" class="mbm_videoManage_box_topBar_item"
				:class="{'mbm_videoManage_box_topBar_item_active':topChange==2}" data-index="2"
				@click="changeTop($event)">回收站</div>
		</div>
		<div class="mbm_videoManage_box_content">
			<div v-if="!isCheck" class="mbm_videoManage_box_content_left">
				<div class="mbm_videoManage_box_content_left_title">目录</div>
				<div class="mbm_videoManage_box_content_left_content">
					<floader ref="floader" :floader_data="menuData" :allData="menuData" :currentId="currentId"
						:currentIndex="currentIndex" :loadingShow="floaderLoadingShow" :parentItem="{}"
						:selectItem="popoverSelectItem" :topChange="parseInt(topChange)">
					</floader>
				</div>
			</div>


			<div class="mbm_videoManage_box_content_right">
				<div class="mbm_videoManage_box_content_right_topbar">
					<div class="mbm_genxin_time" @click="changeUpdataTime">
						更新时间
						<span class="mbm-iconfont mbm-jiantou"
							:style="{'transform':updataTime==1?'rotateX(180deg)':'rotateX(0)'}"></span>
					</div>
					<span v-if="isPageOrModel == 1" class="mbm-iconfont mbm-fenleimokuai mbm_qrcode"
						@click="changeFileListStyle"></span>
					<div class="mbm_search_box">
						<input placeholder="输入标题搜索" v-model="searchValue" @keydown="enterSearch" />
						<div>
							<span class="mbm-iconfont mbm-suosou" @click="searchTitle"></span>
						</div>
					</div>
				</div>

				<!-- 审核 -->
				<div v-if="isCheck">
					<div class="mbm_adminCheckBox">
						<div class="mbm_allSelect_checkbox">
							<a-checkbox v-model:checked="checkAll" :indeterminate="indeterminate"
								@change="onCheckAllChange">已选中{{fileSelectList.length}}个文件
							</a-checkbox>
						</div>

						<div class="mbm_select_box">
							<div class="mbm_check_inputBox">
								<a-input v-model:value="checkInfo.uid" @pressEnter="checkInputChange"
									placeholder="请输入用户ID" />
								<span class="mbm-iconfont mbm-suosou" @click="checkInputChange"></span>
							</div>

							<div v-if="false" class="mbm_select_item">
								<span>排序</span>
								<a-select v-model:value="screenValue" label-in-value style="width: 120px"
									:options="screenOptions" @change="screenHandleChange">
								</a-select>
							</div>

							<div class="mbm_select_item">
								<span>审核</span>
								<a-select v-model:value="checkValue" label-in-value style="width: 120px"
									:options="checkOptions" @change="checkHandleChange">
								</a-select>
							</div>

						</div>
					</div>

					<div class="mbm_check_btnBox">
						<a-button type="primary" class="mbm_check_btn" style="background: #09bd71;"
							@click="checkFiles(1)">通过</a-button>
						<a-button type="primary" class="mbm_check_btn" style="background: #C69D08;"
							@click="checkFiles(2)">有疑问</a-button>
						<a-button type="primary" class="mbm_check_btn" @click="checkFiles(3)" danger>删除</a-button>
						<a-button type="primary" class="mbm_check_btn" style="background: #000000;"
							@click="checkFiles(4)">删除+加入黑名单</a-button>
					</div>

				</div>
				<!-- 加载动画 -->
				<loading defaultClass="mbm_loading" :loadingShow="loadingShow" :type="4" title=""
					:style="isCheck?'height:calc(100% - 54px - 72px - 52px - 52px);':''">
				</loading>

				<a-checkbox-group class="mbm_checkbox_group" v-model:value="fileSelectList" @change="checkBoxChange"
					:style="isCheck?'height:calc(100% - 54px - 72px - 52px - 52px);':''">
					<!-- 文件列表宫格样式 -->
					<div v-if="fileListStyle == 1&&loadingShow==false&&currentId!=null&&currentIndex!=null"
						class="mbm_box_content_right_fileList_gongge">
						<!-- 空页面 -->
						<div v-if="contentList.length==0"
							style="height: 100%;display: flex;align-items: center;position: absolute;width: 100%;top:0;left: 0;">
							<a-empty :image="simpleImage" style="flex: 1;" description="空空如也" />
						</div>
						<div class="mbm_box_content_right_fileList_gongge_content">

							<div v-if="topChange==1&&!isCheck" class="mbm_upload_box">
								<div>
									<span class="mbm-iconfont mbm-shangchuan-2"></span>
									<div>上传</div>
								</div>
								<input type="file" @change="getVideo($event,'video')"
									:accept="pageType==1?'.mp4, .flv, .avi, .mov':'.mp3, .m4a, .wav, .ogg, .asf, .aiff, .svcd, .vcd'"
									multiple="multiple" />
							</div>
							<!-- 上传进度 -->
							<div class="mbm_file_item" v-for="(item,index) in newUploadList" :key="item.id">
								<div class="mbm_upload_progressBox">
									<div class="mbm_upload_progressBox_content">
										<a-progress :percent="item.progress" status="active" :showInfo="false" />
										<div class="mbm_upload_progressBox_text">上传中，请稍后...</div>
									</div>

								</div>
							</div>

							<div class="mbm_file_item" v-for="(item,index) in contentList" :key="item.id"
								:data-index="index">
								<!-- model:多选选择框 -->
								<div v-if="isPageOrModel==2||isCheck" class="mbm_file_item_checkBox">
									<a-checkbox :value="item.file_key"></a-checkbox>
								</div>
								<div class="mbm_file_item_top" @click="openPlayer(item)">
									<a-image :src="item.cover_path" :preview="false" :width="'100%'" :height="'100%'" />
									<div class="mbm_file_item_top_Vtag">v1</div>
									<span class="mbm-iconfont  mbm_file_item_top_type"
										:class="{'mbm-shipin3':pageType==1,'mbm-shengyin':pageType==2,}"></span>
								</div>
								<div class="mbm_file_item_bottom">
									<div class="mbm_file_item_bottom_left">
										<div v-if="!((selectItem.id==item.id)&&(selectItem.index==item.index)&&renameInputShow)"
											class="mbm_file_item_bottom_left_title">
											{{item.filename?item.filename:'默认标题'}}
										</div>
										<div v-if="(selectItem.id==item.id)&&(selectItem.index==item.index)&&renameInputShow"
											class="mbm_file_item_bottom_left_title">
											<input class="mbm_reName_input" ref="renameInput" :value="item.filename"
												@blur="fileRename($event,item)" v-focus />
										</div>
										<div class="mbm_file_item_bottom_left_subtitle">
											{{item.updated_at+' '+item.filesize}}
											<span v-if="isCheck" class="mbm_uid"
												@click="tapUID(item)">UID:{{item.uid}}</span>
										</div>
									</div>
									<div>
										<a-popover placement="left" overlayClassName="mbm_defult_style_ji81"
											:visible="item.id==selectItem.id&&item.index==selectItem.index&&antPopoverShow"
											title="" trigger="click">
											<template #content style="padding: 0;">
												<div @click="hideAntPopover" class="mbm_handle_box_content_list"
													style="width: 166px;position: unset;">
													<div v-if="topChange == 1" @click="openFileRename($event,item)">
														<span class="mbm-iconfont mbm-xiugai"
															style="margin-right: 8px;font-size: 14px;"></span>重命名
													</div>
													<div v-if="topChange == 1" @click="changeLink($event,item)">
														<span class="mbm-iconfont mbm-icon-"
															style="margin-right: 8px;font-size: 14px;"></span>转换成链接
													</div>
													<div v-if="topChange == 1" @click="changeQrcode($event,item)">
														<span class="mbm-iconfont mbm-erweima"
															style="margin-right: 8px;font-size: 14px;"></span>转换成二维码
													</div>
													<div v-if="topChange == 1" @click="downLoadFile($event,item)">
														<span class="mbm-iconfont mbm-xiazai1"
															style="margin-right: 8px;font-size: 14px;"></span>下载源文件
													</div>
													<div v-if="topChange == 1"
														@click="openMoveFile($event,selectItem.name)">
														<span class="mbm-iconfont mbm-yidong"
															style="margin-right: 8px;font-size: 14px;"></span>移动到
													</div>
													<div v-if="topChange == 2"
														@click="openMoveFile($event,selectItem.name)">
														<span class="mbm-iconfont mbm-fenleimokuai"
															style="margin-right: 8px;font-size: 14px;"></span>恢复
													</div>
													<div class="mbm_divider_box">
														<a-divider style="margin: 0;paddiing:0;" />
													</div>

													<div @click="openDeleteFile($event,selectItem.name)">
														<span class="mbm-iconfont mbm-ashbin"
															style="margin-right: 8px;font-size: 14px;"></span>删除
													</div>
												</div>
											</template>
											<a-button v-if="isPageOrModel==1"
												class="mbm_file_item_bottom_right mbm_open_handle_box" type="default"
												style="border: unset;padding: 0;"
												@click.stop='openAntPopover($event,item)'>
												<span class="mbm-iconfont mbm-sangediandian"
													style="font-size: 12px;"></span>
											</a-button>
										</a-popover>
									</div>

								</div>
							</div>
						</div>
					</div>


					<!-- 文件列表样式 -->
					<div v-if="fileListStyle == 2&&loadingShow==false&&currentId!=null&&currentIndex!=null"
						:style="'height:100%'" class="mbm_box_content_fileList_List">
						<!-- 空页面 -->
						<div v-if="contentList.length==0"
							style="height: 100%;display: flex;align-items: center;position: absolute;width: 100%;top:0;left: 0;">
							<a-empty :image="simpleImage" style="flex: 1;" description="空空如也" />

						</div>
						<div class="mbm_videoQrcode_item mbm_user_select_none "
							:class="{'mbm_videoQrcode_item_active':fileIndex==index}"
							v-for="(item,index) in contentList" :key="item.id" :data-index="index"
							@click="changeFile(index,item)">
							<!-- model:多选选择框 -->
							<div v-if="isPageOrModel==2||isCheck" @click.stop="false"
								class="mbm_fileList_more_checkBox">
								<a-checkbox :value="item.file_key"></a-checkbox>
							</div>

							<div class="mbm_videoQrcode_item_left" style="margin-right: 0;">
								<div class="mbm_videoQrcode_item_left_left">
									<div>
										<div class="mbm_videoQrcode_item_imgBox" @click="openPlayer(item)">
											<a-image :src="item.cover_path" :preview="false" :width="'100%'"
												:height="'100%'" />
											<span>v1</span>
										</div>
										<div class="mbm_videoQrcode_item_left_textBox">
											<div class="mbm_videoQrcode_item_title ">
												<div
													v-if="!((selectItem.id==item.id)&&(selectItem.index==item.index)&&renameInputShow)">

													<span class="text_overHidden"
														style="max-width: 70%;display: inline-block;vertical-align: middle;">{{item.filename?item.filename:'默认标题'}}</span>
													<span class="mbm-iconfont mbm-xiugai mbm_rename_btn"
														style="width: 20%;vertical-align: middle;"
														@click.stop="openFileRename($event,item)"></span>
												</div>
												<input @click.stop="false"
													v-if="(selectItem.id==item.id)&&(selectItem.index==item.index)&&renameInputShow"
													class="mbm_reName_input" ref="renameInput" :value="item.filename"
													@blur="fileRename($event,item)" v-focus />
											</div>
											<div class="mbm_scanCount" v-if="item.code_statistic">
												<span>今日播放{{item.code_statistic.today_totall}}次</span>
												<span>昨日播放{{item.code_statistic.yesterday_totall}}次</span>
												<span>近7日播放{{item.code_statistic.nearly7_totall}}次</span>
												<span>近30日播放{{item.code_statistic.totall}}次</span>
											</div>
											<div class="mbm_expire_tip" v-if="item.expire_tip">
												{{item.expire_tip}}
											</div>
											<div v-if="isCheck" class="mbm_uid" @click.stop="tapUID(item)">
												UID:{{item.uid}}</div>
										</div>
									</div>

									<div>
										<span class="mbm_file_size">{{item.filesize}}</span>
										<span class="mbm_file_time">{{item.updated_at}}</span>
									</div>

								</div>

								<div class="mbm_videoQrcode_item_left_right">

									<a-popover placement="left" overlayClassName="mbm_defult_style_ji81"
										:visible="item.id==selectItem.id&&item.index==selectItem.index&&antPopoverShow"
										title="" trigger="click">
										<template #content style="padding: 0;">
											<div @click="hideAntPopover" class="mbm_handle_box_content_list"
												style="width: 166px;position: unset;">
												<div v-if="topChange == 1" @click="openFileRename($event,item)">
													<span class="mbm-iconfont mbm-xiugai"
														style="margin-right: 8px;font-size: 14px;"></span>重命名
												</div>
												<div v-if="topChange == 1" @click="changeLink($event,item)">
													<span class="mbm-iconfont mbm-icon-"
														style="margin-right: 8px;font-size: 14px;"></span>转换成链接
												</div>
												<div v-if="topChange == 1" @click="changeQrcode($event,item)">
													<span class="mbm-iconfont mbm-erweima"
														style="margin-right: 8px;font-size: 14px;"></span>转换成二维码
												</div>
												<div v-if="topChange == 1" @click="downLoadFile($event,item)">
													<span class="mbm-iconfont mbm-xiazai1"
														style="margin-right: 8px;font-size: 14px;"></span>下载源文件
												</div>
												<div v-if="topChange == 1"
													@click="openMoveFile($event,selectItem.name,'move')">
													<span class="mbm-iconfont mbm-yidong"
														style="margin-right: 8px;font-size: 14px;"></span>移动到
												</div>

												<div v-if="topChange == 2"
													@click="openMoveFile($event,selectItem.name,'move')">
													<span class="mbm-iconfont mbm-fenleimokuai"
														style="margin-right: 8px;font-size: 14px;"></span>恢复
												</div>
												<div class="mbm_divider_box">
													<a-divider style="margin: 0;paddiing:0;" />
												</div>

												<div @click="openDeleteFile($event,selectItem.name)">
													<span class="mbm-iconfont mbm-ashbin"
														style="margin-right: 8px;font-size: 14px;"></span>删除
												</div>
											</div>
										</template>
										<a-button class="mbm_open_handle_box" type="default" style="border: unset;"
											@click.stop='openAntPopover($event,item)'>
											<span class="mbm-iconfont mbm-sangediandian"
												style="font-size: 14px;"></span>
										</a-button>
									</a-popover>
								</div>
							</div>
						</div>
					</div>
				</a-checkbox-group>
				<div class="mbm_videoManage_box_content_right_footer">
					<a-pagination v-model:current="currentPage" v-model:pageSize="pageSize" :total="total"
						@change="pageChange" />
				</div>

			</div>


		</div>
	</div>
	<!-- </div> -->
	<!-- 移动文件model -->
	<a-modal :title="'将当前文件移动至“'+moveCurrentName+'”'" v-model:visible="moveFileModelShow" :footer="null" :width="600">
		<div class="mbm_move_model_center_body">
			<div>
				<removeFloader :remove_floader_data="videoData" :currentId="parseInt(moveCurrentId)"
					:currentIndex="parseInt(moveCurrentIndex)" :noSelectList="{}" :parentItem="{}">
				</removeFloader>
			</div>
		</div>
		<div class="mbm_move_model_center_footer">
			<div class="mbm_move_model_center_footer_left">
				<a-button class="mbm_move_model_center_footer_btn" @click.stop="moveModelNewChild"><span
						class="mbm-iconfont mbm-jia" style="font-size: 14px;"></span><span>新建目录</span>
				</a-button>
			</div>
			<div class="mbm_move_model_center_footer_right">
				<a-button class="mbm_move_model_center_footer_btn" @click.stop="closeMoveFileModel">取消
				</a-button>
				<a-button type="primary" :loading="confirmLoading"
					class="mbm_move_model_center_footer_btn mbm_move_define" @click.stop="moveFile">确定
				</a-button>
			</div>
		</div>
	</a-modal>

	<!-- 预览文件model -->
	<a-modal :title="pageType==1?'视频播放器':'音频播放器'" v-model:visible="mbmPlayerModelShow" :footer="null"
		:destroyOnClose="true" :width="600">

		<player :pageType="pageType" :info="selectItem"></player>

		<div v-if="isCheck" class="mbm_adminCheckBox_model">
			<div class="mbm_check_btnBox">
				<a-button type="primary" class="mbm_check_btn" style="background: #09bd71;" @click="checkOneFile(1)">通过
				</a-button>
				<a-button type="primary" class="mbm_check_btn" style="background: #C69D08;" @click="checkOneFile(2)">有疑问
				</a-button>
				<a-button type="primary" class="mbm_check_btn" @click="checkOneFile(3)" danger>删除</a-button>
				<a-button type="primary" class="mbm_check_btn" style="background: #000000;" @click="checkOneFile(4)">
					删除+加入黑名单</a-button>
			</div>
		</div>
	</a-modal>
</template>

<script>
	import {
		ref,
		watch,
		h
	} from "vue";
	import bus from '/src/assets/js/bus.js';
	import player from '/src/components/player.vue';
	import floader from '/src/components/floader.vue';
	import removeFloader from '/src/components/removeFloader.vue';
	import loading from '/src/components/loading.vue';
	import mbm_page from '/src/components/mbm_page.vue';
	import {
		Empty,
		Modal,
		message,
	} from 'ant-design-vue';

	import md5 from 'md5'
	import baseFunc from '../../config/base_func.js'
	import {
		v4 as uuidv4
	} from 'uuid'
	import axios from "axios";
	export default {
		name: 'AudioManage',
		components: {
			floader,
			removeFloader,
			loading,
			mbm_page,
			player,

		},
		emits: ['getlistsfromLib'],
		props: {
			isPageOrModel: {
				type: Number,
				default: 1,
			}, //1:页面；2：弹窗
		},
		data() {
			return {
				isCheck: false, //是否审核
				checkInfo: {
					status: 0,
					qrcode_type: 2,
					uid: '',
				},
				checkDocument: '',
				indeterminate: false,
				checkAll: false,
				screenValue: {
					value: 1,
					label: '默认'
				},
				screenOptions: [{
					value: 1,
					label: '默认'
				}, {
					value: 2,
					label: '日扫描量'
				}, {
					value: 3,
					label: '昨日扫描量'
				}, {
					value: 4,
					label: '近7日扫描量'
				}, {
					value: 5,
					label: '近30日扫描量'
				}, {
					value: 6,
					label: '按用户排序'
				}, ],
				checkValue: {
					value: 0,
					label: '待审核'
				},
				checkOptions: [{
					value: 0,
					label: '待审核'
				}, {
					value: 1,
					label: '审核通过'
				}, {
					value: 3,
					label: '有疑问'
				}, {
					value: 6,
					label: '已删除'
				}, {
					value: 7,
					label: '机审删除'
				}, {
					value: 8,
					label: '机审疑问'
				}, {
					value: -1,
					label: '全部'
				}, ],

				pageType: 2, //页面类型 1：视频库 2：音频库 3：图片库 4：二维码 5：链接’
				fileSelectList: ref([]),
				mbmPlayerModelShow: false,
				newUploadList: [], //上传列表
				confirmLoading: false,
				moveCurrentId: 0,
				moveCurrentIndex: 1,
				moveCurrentName: '全部活码',
				moveFileModelShow: false,
				antPopoverShow: false,
				renameInputShow: false,

				simpleImage: Empty.PRESENTED_IMAGE_SIMPLE, //空页面图片

				pageSize: 20, //每页显示20条数据
				currentPage: 1, //当前页码
				total: 0, //总记录数

				items: [],
				popoverSelectItem: {}, //文件夹当前选中文件夹
				selectItem: {}, //当前选中文件
				selectDocument: null,
				btnList: ["打开方式", "统计", "编辑", "下载", "复制", "删除"],
				selectItemToTop: 0,
				selectItemToLeft: 0,
				handleShow: false,
				handleId: null,
				fileIndex: 0,
				loadingShow: true,
				floaderLoadingShow: true,
				topChange: 1,
				/*1:视频文件。2:回收站*/
				fileListStyle: 1,
				/*1:宫格。2:列表*/
				updataTime: 1,
				/*1:降序。2:升序*/
				searchValue: '',
				currentId: 0,
				/*当前选中文件夹id*/
				currentIndex: 1,
				/*当前选中文件夹层级*/
				/*当前目录下标*/
				contentList: [], //文件夹下的数据
				menuData: [],
				videoData: [],
				backData: [],
				/* videoData: [{
									id: 0,
									type: 'catalogue',
									title: '全部活码',
									index: 1,
									openStatus: true,
									child: [{
											id: 0,
											index: 2,
											type: 'video',
											title: '两分钟了解分秒帧.mp4',
											time: '2021-10-16 17:27',
										},
										{
											id: 1,
											type: 'catalogue',
											title: '汇总页',
											index: 2,
											openStatus: true,
											time: '2021-10-16 17:27',
											child: [{
												id: 0,
												index: 2,
												type: 'video',
												title: '汇总页.mp4',
												time: '2021-10-16 17:27'
											}],
										},
										{
											id: 2,
											type: 'catalogue',
											title: '默认目录1',
											index: 2,
											openStatus: true,
											time: '2021-10-16 17:27',
											child: [{
												id: 0,
												index: 3,
												type: 'video',
												title: '默认目录.mp4',
												time: '2021-10-16 17:27'
											}, {
												id: 1,
												index: 3,
												type: 'video',
												title: '默认目录.mp4',
												time: '2021-10-16 17:27'
											}, {
												id: 2,
												index: 3,
												type: 'catalogue',
												title: '默认目录1-1',
												openStatus: true,
												fileList: [],
												child: [{
													id: 0,
													index: 4,
													type: 'video',
													title: '默认目录.mp4',
													time: '2021-10-16 17:27'
												}, {
													id: 1,
													index: 4,
													type: 'video',
													title: '默认目录.mp4',
													time: '2021-10-16 17:27'
												}, {
													id: 2,
													index: 4,
													type: 'catalogue',
													title: '默认目录1-1-1',
													openStatus: true,
													child: [{
														id: 0,
														index: 5,
														type: 'video',
														title: '默认目录.mp4',
														time: '2021-10-16 17:27'
													}, {
														id: 1,
														index: 5,
														type: 'video',
														title: '默认目录.mp4',
														time: '2021-10-16 17:27'
													}, {
														id: 2,
														index: 5,
														type: 'video',
														title: '默认目录.mp4',
														time: '2021-10-16 17:27'
													}, {
														id: 3,
														index: 5,
														type: 'video',
														title: '默认目录.mp4',
														time: '2021-10-16 17:27'
													}, {
														id: 4,
														index: 5,
														type: 'video',
														title: '默认目录.mp4',
														time: '2021-10-16 17:27'
													}, {
														id: 5,
														index: 5,
														type: 'video',
														title: '默认目录.mp4',
														time: '2021-10-16 17:27'
													}, {
														id: 6,
														index: 5,
														type: 'video',
														title: '默认目录.mp4',
														time: '2021-10-16 17:27'
													}, {
														id: 7,
														index: 5,
														type: 'video',
														title: '默认目录.mp4',
														time: '2021-10-16 17:27'
													}, {
														id: 8,
														index: 5,
														type: 'video',
														title: '默认目录.mp4',
														time: '2021-10-16 17:27'
													}, {
														id: 9,
														index: 5,
														type: 'video',
														title: '默认目录.mp4',
														time: '2021-10-16 17:27'
													}, {
														id: 10,
														index: 5,
														type: 'video',
														title: '默认目录.mp4',
														time: '2021-10-16 17:27'
													}, {
														id: 11,
														index: 5,
														type: 'video',
														title: '默认目录.mp4',
														time: '2021-10-16 17:27'
													}],

												}],
											}, {
												id: 3,
												index: 3,
												type: 'catalogue',
												title: '默认目录1-2',
												openStatus: true,
											}],
										}
									],
								}], */

			}
		},
		created() {
			if (typeof check_power != 'undefined' && parseInt(check_power) == 1) {
				this.isCheck = true;
			}

			//获取目录
			this.getCatalogue();
			this.loadingShow = true;
			this.getCatalogueData(); //获取文件夹下数据
			this.catalogueListener(); //文件夹监听事件

			//文件移动model切换当前选中的文件夹
			bus.$on('removeCatalogueChange', e => {
				this.moveCurrentId = parseInt(e.id);
				this.moveCurrentIndex = parseInt(e.index);
				this.moveCurrentName = e.name;
			});

			//监听选中文件参数
			watch(
				() => this.fileSelectList,
				val => {
					this.$emit('getlistsfromLib', this.fileSelectList);
					if (this.contentList.length != 0) {
						this.indeterminate = !!this.fileSelectList.length && this.fileSelectList.length < this
							.contentList
							.length;
						this.checkAll = this.fileSelectList.length === this.contentList.length;
					} else {
						this.indeterminate = false;
						this.checkAll = false;
					}


				},
			);

		},
		beforeDestroy() {

		},
		methods: {
			/* 审核文件 */
			checkModel(file_keys, status, type) {
				let that = this;
				let content = '';
				switch (status) {
					case 1:
						content = '通过';
						break;
					case 2:
						content = '有疑问';
						break;
					case 3:
						content = '删除';
						break;
					case 4:
						content = '删除+拉入黑名单';
						break;
				}

				Modal.confirm({
					title: '信息',
					content:()=>h("div",null,[
						h("span",null,"确认执行"),
						h("span",{style:"color:red;"},content),
						h("span",null,"操作")
					]),
					okText: content,
					cancelText: '取消',
					icon: ()=>h("span",{class: 'mbm-anticon mbm-iconfont mbm-wenhao-xianxingyuankuang'},""),
					onOk() {

						that.$apiRequest.post("/user/checkAttachManage", {
							file_keys: file_keys,
							status: status,
						}).then(res => {

							if (res.data.code == 200) {

								message.success(res.data.message);
								// that.loadingShow = true;
								// that.getCatalogueData();
								if (type == 1) {
									let index = that.contentList.findIndex(item => {
										if (item.file_key == file_keys) {
											return true;
										}

									});
									that.contentList.splice(index, 1);
								} else if (type == 2) {

									for (let i = 0; i < that.fileSelectList.length; i++) {

										let index = that.contentList.findIndex(item => {
											if (item.file_key == that.fileSelectList[i]) {
												return true;
											}

										});
										that.contentList.splice(index, 1);
									}
								}

							}
							that.fileSelectList = [];
							that.indeterminate = false;
							that.checkAll = false;
						}).catch(err => {
							that.fileSelectList = [];
							that.indeterminate = false;
							that.checkAll = false;

							if (err.code != 200) {
								Modal.info({
									title: () => err.data.message,
									content: '',
									okText: '我知道了',
									onOk() {},
								});
							}

						});

					},
					onCancel() {

					},
				});
			},
			checkFiles(status) {
				let file_keys = '';
				if (this.fileSelectList.length != 0) {
					for (let i = 0; i < this.fileSelectList.length; i++) {
						file_keys += this.fileSelectList[i] + '';
						if (i != this.fileSelectList.length - 1) {
							file_keys += ','
						}
					};
					this.checkModel(file_keys, status, 2);
				} else {
					message.error('未选择');
				}


			},
			/* 审核一个文件 */
			checkOneFile(status) {
				let file_keys = this.selectItem.file_key;
				this.mbmPlayerModelShow = false;
				this.checkModel(file_keys, status, 1);
			},
			/* 排序改变 */
			screenHandleChange(e) {


			},
			//审核改变
			checkHandleChange(e) {
				this.currentPage = 1;
				this.checkInfo.status = e.value;

				//获取目录
				this.getCatalogue();
				this.loadingShow = true;
				this.getCatalogueData(); //获取文件夹下数据
				this.fileSelectList = [];
				this.indeterminate = false;
				this.checkAll = false;
			},
			//用户ID搜索
			checkInputChange(e) {
				this.currentPage = 1;

				this.fileSelectList = [];
				this.indeterminate = false;
				this.checkAll = false;
				//获取目录
				this.getCatalogue();
				this.loadingShow = true;
				this.getCatalogueData(); //获取文件夹下数据
			},
			//全选
			onCheckAllChange(e) {
				let file_keysList = [];
				for (let i = 0; i < this.contentList.length; i++) {
					file_keysList.push(this.contentList[i].file_key);
				}
				this.fileSelectList = e.target.checked ? file_keysList : [];
				this.indeterminate = false;

			},
			// 文件多选框改变
			checkBoxChange(e) {
				let fileSelectList = e;

				this.$emit('getlistsfromLib', this.fileSelectList);
			},
			//打开播放model
			openPlayer(item) {
				this.selectItem = item;
				this.mbmPlayerModelShow = true;
			},
			//获取目录
			getCatalogue() {
				let that = this;
				//获取目录
				this.$apiRequest.post("/user/getCatalogue", {
					type: this.pageType,
					check_power: typeof check_power != 'undefined' ? check_power : '',
				}).then(res => {

					this.videoData = res.data.data;
					if (this.topChange == 1) {
						this.menuData = this.videoData;
					} else {
						this.menuData = this.backData;
					}

					this.floaderLoadingShow = false;
				}).catch(err => {
					this.floaderLoadingShow = false;
				});
			},
			//获取文件夹数据
			getCatalogueData() {
				let that = this;
				let param = {};
				if (typeof check_power != 'undefined' && check_power == 1) {

					param = {
						id: this.currentId,
						type: this.pageType,
						size: this.pageSize,
						page: this.currentPage,
						sort: this.updataTime,
						keywords: this.searchValue,
						check_power: typeof check_power != 'undefined' ? check_power : '',
						status: this.checkInfo.status,
						qrcode_type: this.checkInfo.qrcode_type,
						uid: this.checkInfo.uid,
					};
				} else {
					param = {
						id: this.currentId,
						type: this.pageType,
						size: this.pageSize,
						page: this.currentPage,
						sort: this.updataTime,
						keywords: this.searchValue,
						check_power: '',
					};
				}
				this.$apiRequest.post("/user/CatalogData", param).then(res => {

					if (res.data.code == 200) {
						that.loadingShow = false;
						that.contentList = res.data.data.data;
						that.total = res.data.data.total;

					}
				}).catch(err => {

					if (err.code != 200) {
						that.loadingShow = false;
						Modal.info({
							title: () => err.data.message,
							content: '',
							okText: '我知道了',
							onOk() {

							},
						});
					}
				});
			},

			//文件夹监听事件
			catalogueListener() {
				let that = this;
				//文件夹切换
				bus.$on('catalogueChange', e => {
					let that = this;
					this.currentPage = 1;
					this.currentId = parseInt(e.id);
					this.currentIndex = parseInt(e.index);
					this.loadingShow = true;
					if (this.topChange == 1) {
						//获取文件夹数据
						this.getCatalogueData();
					} else if (this.topChange == 2) {
						//获取回收站数据
						this.getRecycleData();
					}

				});
				//气泡框文件夹选择
				bus.$on('popoverSelect', e => {
					this.popoverSelectItem = e.item;
				});
				//删除文件夹
				bus.$on('deleteCatalogue', e => {
					let id = e.id;
					let type = e.type; //1:气泡框；2：移动model
					this.$apiRequest.post("/user/deleteFile", {
						id: id,
					}).then(res => {
						if (res.data.code == 200) {
							if (type == 1) {
								message.success('目录删除成功');
							}
							that.getCatalogue();
						}
					}).catch(err => {
						if (err.code != 200) {
							Modal.info({
								title: () => err.data.message,
								content: '',
								okText: '我知道了',
								onOk() {},
							});
						}
					});
				});
				//新建子目录
				bus.$on('newChild', e => {
					let id = e.id;
					let index = e.index;
					let type = e.type;
					this.$apiRequest.post("/user/newlyBuildFile", {
						id: id,
						type: this.pageType,
					}).then(res => {
						let item = res.data.data;
						let arry = {
							index: index + 1
						};
						for (var i in arry) {
							item[i] = arry[i]
						}
						if (res.data.code == 200) {
							that.getCatalogue();
							bus.$emit(type == 1 ? 'openRenameChild' : 'openRenameModelChild', {
								'item': item,
							})
							setTimeout(function() {
								message.success('目录新建成功');	
							}, 1000);
						}
					}).catch(err => {
						if (err.code != 200) {
							Modal.info({
								title: () => err.data.message,
								content: '',
								okText: '我知道了',
								onOk() {},
							});
						}
					});

				});
				//文件夹移动
				bus.$on('moveCatalogue', e => {
					let id = e.id;
					let pid = e.pid;
					this.$apiRequest.post("/user/moveFile", {
						id: id,
						pid: pid,
					}).then(res => {
						if (res.data.code == 200) {
							message.success('目录移动成功');
							that.getCatalogue();

						}


					}).catch(err => {
						if (err.code != 200) {
							Modal.info({
								title: () => err.data.message,
								content: '',
								okText: '我知道了',
								onOk() {},
							});
						}
					});

				});
				//文件夹重命名
				bus.$on('rename', e => {
					let id = e.id;
					let index = e.index;
					let value = e.value;
					this.$apiRequest.post("/user/renameFile", {
						id: id,
						title: value,
					}).then(res => {
						if (res.data.code == 200) {
							message.success('重命名成功')
							that.getCatalogue();
						}
					}).catch(err => {
						if (err.code != 200) {
							that.getCatalogue();
							Modal.info({
								title: () => err.data.message,
								content: '',
								okText: '我知道了',
								onOk() {},
							});
						}
					});
				});
			},
			//转换成连接
			changeLink(e, item) {
				window.open(item.transition_link_url);
			},
			//转换成连接
			changeQrcode(e, item) {
				window.open(item.transition_qrcode_url);
			},
			//下载文件 +'&'+encodeURIComponent("zxdvxcvbxc")+'.mp4'
			downLoadFile(e, item) {
				this.$apiRequest.post('/user/getUserDefultSet', {
					
				}).then(res => {
					if(res.data.data.vip_status<1){
						baseFunc.checkState({
							code:11001,
							message:res.data.data.media_download_message,
						}).then(()=>{}).catch(()=>{})
					}else{
						var eleLink = document.createElement('a');
						eleLink.style.display = 'none';
						//eleLink.rel = "noreferrer noopener nofollow"
						//eleLink.target = "_blank"
						eleLink.href = item.download_file_url;
						eleLink.click();
						// 然后移除
						eleLink.remove();
						// window.location.href = item.download_file_url;
					}
				}).catch(err => {
					// message.error(err.message);
				})
				
			},
			//打开删除文件
			openDeleteFile() {
				let name = this.selectItem.filename;
				let id = this.selectItem.id;
				let index = this.selectItem.index;
				let that = this;
				let title = '';
				let content = '';
				let delType = 1;
				if (this.topChange == 1) {
					title = '确认将文件放到回收站吗？';
					content = '当前删除,只会把文件放到回收站,请谨慎操作!';
					delType = 1;
				} else if (this.topChange == 2) {
					title = '确认删除文件吗？';
					content = '当前删除，文件将无法找回，请谨慎操作!';
					delType = 2;
				}
				Modal.confirm({
					title: title,
					content:()=>h("div",{style:"color:red;"},content),
					okText: '确认',
					okType: 'danger',
					cancelText: '取消',
					icon: ()=>h("span",{class: 'mbm-anticon mbm-iconfont mbm-wenhao-xianxingyuankuang'},""),
					onOk() {
						that.$apiRequest.post("/user/getDeleteFile", {
							id: id,
							type: that.pageType,
							del: delType,
						}).then(res => {
							if (res.data.code == 200) {
								if (delType == 1) {
									message.success('文件已放入回收站');
									that.getCatalogueData();
								} else {
									message.success('删除成功');
									that.getRecycleData();
								}
							}
						}).catch(err => {
							if (err.code != 200) {
								Modal.info({
									title: () => err.data.message,
									content: '',
									okText: '我知道了',
									onOk() {},
								});
							}

						});
					},
					onCancel() {

					},
				});
			},

			/* 移动文件model新建目录 */
			moveModelNewChild(res) {
				let that = this;
				let type = 2;
				let index = this.moveCurrentIndex;
				let id = this.moveCurrentId;

				this.$apiRequest.post("/user/newlyBuildFile", {
					id: id,
					type: this.pageType,
				}).then(res => {
					let item = res.data.data;
					let arry = {
						index: index + 1
					};
					for (var i in arry) {
						item[i] = arry[i]
					}
					if (res.data.code == 200) {
						message.success('目录新建成功');
						that.getCatalogue();


						bus.$emit(type == 1 ? 'openRenameChild' : 'openRenameModelChild', {
							'item': item,
						})

					}

				}).catch(err => {
					if (err.code != 200) {
						Modal.info({
							title: () => err.data.message,
							content: '',
							okText: '我知道了',
							onOk() {},
						});
					}
				});

			},
			/* 关闭移动文件model */
			closeMoveFileModel() {
				this.moveFileModelShow = false;

			},

			/* 移动文件 */
			moveFile() {
				let that = this;
				let index = this.selectItem.index;
				let id = this.selectItem.id;

				let pIndex = this.moveCurrentIndex;
				let pid = this.moveCurrentId;
				this.confirmLoading = true;
				if (this.topChange == 1) {
					that.$apiRequest.post("/user/getMoveFile", {
						/* 文件移动 */
						id: id,
						pid: pid,
						type: this.pageType,
					}).then(res => {
						if (res.data.code == 200) {
							message.success("文件移动成功");
							that.moveFileModelShow = false;
							that.confirmLoading = false;
							this.getCatalogue();
							that.getCatalogueData();
						}
					}).catch(err => {
						if (err.code != 200) {
							that.confirmLoading = false;
							Modal.info({
								title: () => err.data.message,
								content: '',
								okText: '我知道了',
								onOk() {},
							});
						}

					});
				} else {
					that.$apiRequest.post("/user/getDeleteFile", {
						/* 文件恢复 */
						id: id,
						meau_id: pid,
						type: this.pageType,
						del: 3,
					}).then(res => {
						if (res.data.code == 200) {
							message.success("文件恢复成功");
							that.moveFileModelShow = false;
							that.confirmLoading = false;
							that.getRecycleData();
						}
					}).catch(err => {
						if (err.code != 200) {
							that.confirmLoading = false;
							Modal.info({
								title: () => err.data.message,
								content: '',
								okText: '我知道了',
								onOk() {},
							});
						}

					});
				}


			},
			/* 打开文件移动model */
			openMoveFile() {
				this.moveFileModelShow = true;
				this.moveCurrentId = 0;
				this.moveCurrentIndex = 1;
				this.moveCurrentName = '全部活码';
			},
			/* 文件重命名 */
			fileRename(e, item) {
				this.renameInputShow = false;
				let value = e.target.value;
				let that = this;

				if (item.filename != value) {
					item.filename = value;

					this.$apiRequest.post("/user/saveRenameFile", {
						id: item.id,
						title: value,
						type: this.pageType,
					}).then(res => {
						if (res.data.code == 200) {
							message.success("文件重命名成功");
							that.getCatalogueData();
						}
					}).catch(err => {
						if (err.code != 200) {
							that.getCatalogueData();
							Modal.info({
								title: () => err.data.message,
								content: '',
								okText: '我知道了',
								onOk() {},
							});
						}

					});
				};

			},
			//打开文件重命名
			openFileRename(e, item) {
				this.selectItem = item;
				this.renameInputShow = true;

			},
			//Ant气泡框隐藏
			hideAntPopover() {

				this.antPopoverShow = false;
			},
			//Ant气泡框显示
			openAntPopover(e, item) {
				let arr = Object.keys(this.selectItem);
				let that = this;
				if (item.id == this.selectItem.id && item.index == this.selectItem.index && this.antPopoverShow == true) {
					this.hideAntPopover();
				} else {

					this.antPopoverShow = true;
					this.renameInputShow = false;
					this.selectItem = item;

					function emojiDisShow() {
						//改变数据 重要的是在body上绑定事件, 让数据变成false, 最重要的是阻止点击emoji标签时候禁止冒泡到body上,所以用stop,  
						that.hideAntPopover();
						document.body.removeEventListener('click', emojiDisShow) //消失后,为了性能,取消body这个事件就可以了
					}
					document.body.addEventListener('click', emojiDisShow);
				}
			},

			//改变文件列表展示样式
			changeFileListStyle() {
				let that = this;
				if (this.fileListStyle == 1) {
					this.fileListStyle = 2;
				} else {
					this.fileListStyle = 1;
				}
			},
			//从page组件传递过来的当前page
			pageChange(currentpage) {
				this.currentPage = currentpage;
				this.loadingShow = true;

				if (this.topChange == 1) {
					this.getCatalogueData();
				} else {
					this.getRecycleData();
				};

				this.fileSelectList = [];
				this.indeterminate = false;
				this.checkAll = false;
			},
			// 打开关闭目录操作框
			openCloseHandle(e, item) {
				var that = this;
				this.handleId = item.id;
				this.handleIndex = item.index;
				this.selectItem = item;
				this.selectDocument = e;
				this.handleShow = true;

				function emojiDisShow() {
					//改变数据 重要的是在body上绑定事件, 让数据变成false, 最重要的是阻止点击emoji标签时候禁止冒泡到body上,所以用stop,  
					that.handleShow = false;
					document.body.removeEventListener('click', emojiDisShow) //消失后,为了性能,取消body这个事件就可以了
				}
				document.body.addEventListener('click', emojiDisShow);

			},
			//改变选中文件
			changeFile(index, item) {
				this.openPlayer(item);
				this.fileIndex = index;
				this.handleShow = false;
			},
			// 顶部切换
			changeTop(e) {
				this.topChange = e.currentTarget.dataset.index;
				this.fileSelectList = [];
				this.loadingShow = true;
				this.currentId = 0;
				this.currentIndex = 1;
				if (this.topChange == 1) {
					this.getCatalogue();
					this.getCatalogueData();
					this.menuData = this.videoData;
				} else {
					this.backData = [{
						id: 0,
						index: 1,
						type: 'catalogue',
						title: '回收站',
						openStatus: true,
						child: [],
					}]
					this.menuData = this.backData;
					this.getRecycleData();

				}
			},
			//获取回收站数据
			getRecycleData() {

				let that = this;
				this.$apiRequest.post("/user/getRecycleData", {
					type: this.pageType,
					page: this.curretPage,
					size: this.pageSize,
					sort: this.updataTime,
					keywords: this.searchValue,
					check_power: typeof check_power != 'undefined' ? check_power : '',
				}).then(res => {


					if (res.data.code == 200) {
						that.contentList = res.data.data.data;
						this.loadingShow = false;
					}
				}).catch(err => {

					if (err.code != 200) {
						this.loadingShow = false;
						Modal.info({
							title: () => err.data.message,
							content: '',
							okText: '我知道了',
							onOk() {

							},
						});
					}

				});
			},
			// 更新时间
			changeUpdataTime() {
				let that = this;
				this.currentPage = 1;
				this.fileSelectList = [];
				if (this.updataTime == 1) {
					this.updataTime = 2;
				} else {
					this.updataTime = 1;
				}
				this.loadingShow = true;

				if (this.topChange == 1) {
					this.getCatalogueData();
				} else {
					this.getRecycleData();
				}
			},
			//回车标题搜索
			enterSearch(e) {
				//Enter键的代码就是13
				if (e.keyCode == 13) {
					this.searchTitle();
				}

			},
			//标题搜索
			searchTitle() {
				this.currentPage = 1;
				this.fileSelectList = [];
				this.loadingShow = true;
				if (this.topChange == 1) {
					this.getCatalogueData();
				} else {
					this.getRecycleData();
				}

			},
			//点击UID
			tapUID(item) {

				this.checkInfo.uid = item.uid;
				this.searchTitle();
			},
			//目录切换
			changeCurrentCatalogue(e) {
				let index = e.currentTarget.dataset.index;
				this.currentCatalogue = index;
				this.contentList = this.videoData[index].fileList;
			},
			//上传文件
			getVideo(e) {

				let files = event.target.files;
				let that = this;
				var video_totoal = files.length,
					video_upload_totoal = 0;
				for (let i = 0; i < files.length; i++) {
					this.newUploadList.push({
						progress: 0
					});
					this.$apiRequest.uploadMediaToQiniu(this, files[i], progress => {
						/*进度条*/

						this.newUploadList[progress.extra_data_defult.i].progress = progress.percent;
					}, complete => {

					}, {
						"meau_id": that.currentId,
						"upload_type": this.pageType == 1 ? "video" : "audio",
						"i": i,
						/*附件所在目录id*/
					}).then(res => {
						video_upload_totoal++;
						if (video_upload_totoal == video_totoal) {
							that.getCatalogueData();
							this.newUploadList = [];
						}
					}).catch(err => {
						if (err.code != 200) {
							this.newUploadList = [];
							Modal.info({
								title: () => err.message,
								content: '',
								okText: '我知道了',
								onOk() {

								},
							});
						}
					});
				};

			},
		}

	}
</script>

<style>
	.mbm_upload_progressBox {
		box-sizing: border-box;
		padding: 20px;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.6);
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.mbm_upload_progressBox_content {
		width: 100%;
	}

	.mbm_upload_progressBox_text {
		width: 100%;
		margin-top: 10px;
		font-size: 14px;
		color: white;
		text-align: center;
		line-height: 1;
	}
</style>
