<template>
	<div class="setCon">
		<div class="iframe_wrap">
			<img class="img" src="../assets/imgs/top_black2@2x.png">
			<div class="iframe_border">
				<div class="listCon"  :style="{backgroundColor:videoData.bjcolor}"></div>
				
			</div>
		</div>
		<div  class="set_warp">
			<div class="set_warp_bgColor">
				<p class="fontstyle1" style="margin-top: 18px;margin-bottom: 20px;">背景色</p>
				<div class="set_warp_bgColor1">
					<a-radio-group v-model:value="bgColor" @change="setBgColortype(bgColor)">
						<a-radio :value="1">白色</a-radio>
						<a-radio :value="2">黑色</a-radio>
						<a-radio :value="0">自定义</a-radio>
					</a-radio-group>
					<div class="colorPicker" @click="openColorpicker"  :style="{backgroundColor:colorpickBg}">
						<input ref="colorPic" id="colorPicker1" type="color" name="" value="" @change="setBgColor" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	
	export default {
		data(){
			return{
				bgColor:1,
				colorpickBg:'',
			}
			
		},
		props:{
			videoData:Object,
		},
		methods:{
			handleCancle(){
				this.$emit('closeStyleset');
			},
			handleOk(){
				this.$emit('closeStyleset');
			},
			setBgColortype(e){
				// console.log(e)
				if(e==1){
					this.videoData.bjcolor="#ffffff";
				}else if(e==2){
					this.videoData.bjcolor="#000000";
				}else{
					this.videoData.bjcolor="";
				}
			},
			setBgColor(e){
				// console.log(e)
				this.videoData.bjcolor=e.target.value;
				this.colorpickBg=e.target.value
			},
			openColorpicker(){
				this.$refs.colorPic.dispatchEvent(new MouseEvent('click'))
			}
			
		},
		computed:{
			
		}
	}
</script>

<style scoped>
	#colorPicker1{
		width: 0px;
		height: 0px;
		z-index: -1;
		position: absolute;
		left: -2px;
		top: -4px;
	}
	.colorPicker{
		width: 30px;
		position: relative;
		height: 30px;
		border: 2px solid #e7e7e7;
		border-radius: 4px;
		cursor: pointer;
	}
	.set_warp_bgColor1{
		display: flex;
		align-items: center;
	}
	/deep/.set_warp_bgColor span{
		color:#999999;
	}
	.fontstyle2{
		font-size: 14px;
		color: #e7e7e7;
	}
	.fontstyle1{
		font-size: 14px;
		color: #585858;
		margin-bottom: 25px;
		margin-top: 32px;
	}
	.set_warp{
		flex-grow: 1;
		/* background-color: #f1f1f1; */
		margin-left: 84px;
	}
	.listCon{
		width: 100%;
		height: 100%;
		overflow-y: scroll;
		padding-top: 80px;
		/* background-color: #008000; */
	}
	.listCon::-webkit-scrollbar{
		width: 0;
	}
	.iframe_wrap{height:100%;width:320px;padding:6px;box-shadow:0 0 5px 0 rgba(0,0,0,.2);position:sticky;border-radius:34px;margin:5px;top:5px;}
	.iframe_wrap .img{position: absolute;width:100%;left:0;top:-2px;border-top-left-radius:38px;border-top-right-radius:38px;}
	.iframe_wrap .iframe_border{width:100%;height:100%;overflow: hidden;border-radius:30px;border:1px solid #f3f3f3;}
	.iframe_wrap .iframe_border iframe{width:100%;height:100%;border:none;display: block;}
	/* *{
		background-image: url(/img/video.png);
		
	} */
	.setCon{
		height: 690px;
		display: flex;
		justify-content: space-between;
	}
</style>
