<template>
	<!-- 删除model -->
	<div class="mbm_delete_model" v-if="deleteModelShow">
		<div class="mbm_delete_model_content">

			<div class="mbm_delete_model_content_title">
				<i>
					<svg xmlns="http://www.w3.org/2000/svg" class="" viewBox="0 0 1024 1024" data-icon="question-circle"
						width="26px" height="26px" fill="#faad14" aria-hidden="true">
						<path
							d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z">
						</path>
						<path
							d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7c0-19.7 12.4-37.7 30.9-44.8 59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1 0 80 0 40 40 0 1 0-80 0z">
						</path>
					</svg>
				</i>
				<span>{{title}}</span>
			</div>

			<div class="mbm_delete_model_content_subtitle">
				{{content}}
			</div>

			<div class="mbm_delete_model_content_bottom_btnBox">
				<div class="mbm_delete_model_content_bottom_btn mbm_cancel" @click="cancel">{{cancelText}}</div>
				<div class="mbm_delete_model_content_bottom_btn mbm_define" @click="confirm">{{confirmText}}</div>
			</div>

		</div>
	</div>
</template>

<script>
	import bus from '/src/assets/js/bus.js';
	export default {
		name: 'deleteModel',
		props: {
			title: String,
			content:String,
			cancelText:String,
			confirmText:String,
			deleteModelShow: Boolean,
		},
		methods: {
			cancel() {
				this.$emit('cancel');
			},
			confirm() {
				this.$emit('confirm',{
					id:1
				});
			},
			
		},
	}
</script>

<style>
	/* 删除model */
	.mbm_delete_model {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.6);
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 1000;
	}

	.mbm_delete_model_content {
		box-sizing: border-box;
		padding: 32px 32px 24px;
		font-size: 14px;
		line-height: 1.5;
		word-wrap: break-word;
		color: rgba(0, 0, 0, .85);
		background: #FFFFFF;
		border-radius: 3px;
		box-shadow: inset 0 0 5px rgba(0, 21, 41, .05);

	}

	.mbm_delete_model_content_title {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		font-size: 16px;
		font-weight: bold;
		color: #333333;
	}

	.mbm_delete_model_content_title>i {
		width: 26px;
		height: 26px;
		margin-right: 16px;
	}

	.mbm_delete_model_content_subtitle {
		margin-left: 42px;
		padding-bottom: 10px;
		color: rgba(255, 0, 0, .85);
		font-size: 14px;
		line-height: 1.5;
		margin-top: 8px;
	}

	.mbm_delete_model_content_bottom_btnBox {
		margin-top: 24px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	.mbm_delete_model_content_bottom_btn {
		border-radius: 2px;
		line-height: 32px;
		display: inline-block;
		font-weight: 400;
		text-align: center;
		-ms-touch-action: manipulation;
		touch-action: manipulation;
		cursor: pointer;
		background-image: none;
		white-space: nowrap;
		padding: 0 15px;
		font-size: 14px;
		border-radius: 4px;
		height: 32px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		transition: all .3s cubic-bezier(.645, .045, .355, 1);
		position: relative;
		color: rgba(0, 0, 0, .85);
		background-color: #fff;
		border: 1px solid #d9d9d9;
	}

	.mbm_define {
		border-color: #e64340;
		color: white;
		background: #e64340;
		margin-left: 8px;
	}
</style>
